import { Link } from "react-router-dom"
import "./ReportSec.css"
import Button from "../../../../FemilerCom/Button/Button"
import { useEffect, useState } from "react"
import axios from "axios"
function ReportSec () {
    ///states
    let [enrollments, setEnrollments] = useState();
    const [links, setLinks] = useState();
    const [loader, setLoader] = useState();
    const [alertMsg, setAlertMsg] = useState({code: false, msg: "", classes: ""});
    const [toggleType, setToggleType] = useState(true);
    
    /// sort the table 
    function requestData(url) {
        setLoader(true)
        axios.get(url, {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
          setEnrollments(res.data.payload.data);
          setLinks(res.data.payload.links)
          let response = res.data.payload.data;
          setLoader(false)
        })
      }
      function sortType(e) {
        let myenrollments;
        if(toggleType)  {
          myenrollments = enrollments.sort((a, b) => a.type - b.type);
          setEnrollments(myenrollments);
            setToggleType(!toggleType)
        } else {
          myenrollments = enrollments.sort((a, b) => b.type - a.type);
          setEnrollments(myenrollments);
            setToggleType(!toggleType)
      
        }
      }
      /// delete user
      function dropexam(id) {
        axios.delete("https://api.twindix.com/dashboard/enrollments" + id, {headers: {Authorization: "Bearer " + localStorage.token}}).then((res) => {
          console.log(res)
          if(res.status) {
            setAlertMsg({deleted: true,msg: res.data.message, classes: "alert alert-success alert-dismissible fade show"})
          } else {
            setAlertMsg({deleted: false,msg: "proplem occured", classes:"alert alert-danger alert-dismissible fade show"})
          }
          axios.get("https://api.twindix.com/dashboard/enrollments", {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
            setEnrollments(res.data.payload.data);
            setLinks(res.data.payload.links)
          })
        })
        
      }
          // fetch enrollments
      const fetchAllEnrollments = async () => {
        setLoader(true);
        let allEnrollments = [];
        let currentPage = 1;
        let lastPage = 1;

        try {
            do {
                const response = await axios.get(`https://api.twindix.com/dashboard/enrollments?page=${currentPage}`, {
                    headers: { Authorization: "Bearer " + localStorage.token }
                });
                console.log('Response:', response.data.payload.data); // Log the entire response
                const data = response.data.payload.data;
                const meta = response.data.payload;
                allEnrollments = [...allEnrollments, ...data];
                currentPage = meta.current_page + 1;
                lastPage = meta.last_page;
            } while (currentPage <= lastPage);
        } catch (error) {
            console.error("Error fetching enrollments:", error);
        }

        setEnrollments(allEnrollments);
        setLoader(false);
    };
    useEffect(() => {
      fetchAllEnrollments();
  }, []);
    // fetch users 
  const [users, setUsers] = useState();
  useEffect(() => {
    if(!users) {
      axios.get("https://api.twindix.com/dashboard/users", {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
      setUsers(res.data.payload.data);
      // console.log(res.data.payload.data)
      setLinks(res.data.payload.links)
    })
    }

}, [users, ])

  /// fetch Exams
  let [examList, setexamList ]= useState()
  const fetchExams = (url) => {
    setLoader(true);
    axios
      .get(url, {
        headers: { Authorization: "Bearer " + localStorage.token },
      })
      .then((res) => {
        setexamList(res.data.payload.data);
        console.log(res.data.payload)
        setLinks(res.data.payload.links);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching exams:", error);
        setLoader(false);
      });
  };
  useEffect(() => {
    if (!examList) {
      fetchExams("https://api.twindix.com/dashboard/exams");
    }
    console.log(examList);
  }, [examList]);
    return (
        <>
                  <div className="d-flex flex-column justify-content-center align-items-center">
        <div className={loader ? "loader" : "d-none"}><span className="spinner-border"></span></div>
        <div className={alertMsg.msg ? "d-block position-fixed top-0" : "d-none"}>
          <div className={alertMsg.classes}>{alertMsg.msg}  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  </div>
        </div>
        <table className="table">
  <thead>
    
    <tr>
      <th style={{cursor: "pointer", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px"}} className="tableheader" onClick={() => requestData("https://api.twindix.com/dashboard/enrollments")} scope="col">id</th>
      <th style={{cursor: "pointer"}} className="tableheader" onClick={() => requestData("https://api.twindix.com/dashboard/enrollments?sort=name")} scope="col">Code</th>
      <th style={{cursor: "pointer"}} className="tableheader" onClick={() => requestData("https://api.twindix.com/dashboard/enrollments?sort=name")} scope="col">User Name</th>
      <th style={{cursor: "pointer"}} className="tableheader" onClick={() => requestData("https://api.twindix.com/dashboard/enrollments?sort=email")} scope="col">Finished</th>
      <th style={{cursor: "pointer"}} className="tableheader"  scope="col">Order Id</th>
      <th style={{cursor: "pointer"}} className="tableheader" onClick={sortType} scope="col">Exam Title</th>
      <th style={{cursor: "pointer", borderTopRightRadius: "5px", borderBottomRightRadius: "5px"}} className="tableheader" onClick={sortType} scope="col">Action</th>

    </tr> 
  </thead>
  <tbody>
  {enrollments ? enrollments.map((exam,index) => {
    let  userName, examtitle ;
    users.map((user) => user.id == exam.user_id ? userName = user.name : "")
    examList.map((spasficexam) => spasficexam.id == exam.exam_id ? examtitle = spasficexam.title : "")
      return( <tr key={exam.id}>
      <th className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"} scope="row">{exam.user_id}</th>
      <td className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"}>{exam.code}</td>
      <td className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"}>{userName}</td>
      <td className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"}>{exam.finished == 1 ? <span className="text-success">Finished</span> : <span className="text-danger">Not Finished Yet</span>}</td>
      <td className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"}>{exam.order_id}</td>
      <td className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"}>{examtitle}</td>
      <td className={index % 2 == 0 ? "tablerow tablerowColor Flex" : "tablerow Flex"}>
      {exam.finished == 1 ? 
        <a href={"/reportPage?enro_id=" + exam.id + "&exam_id=" + exam.exam_id  + "&isAdmin=" + "1"}  
          style={{margin: "auto"}} 
          className="btn btn-primary" 
          target="_blank" 
          rel="noreferrer">Show Report</a> : 
        <div style={{margin: "auto", background: "#79B934", border: "0", color: "white", cursor: "default"}} 
        className="btn">No Report Yet</div>}
    </td>
    </tr>)
  })  : "There is no enrollments"}
  </tbody>
</table>
  </div>
        </>
    )
  }
  
  export default ReportSec
  // <td className={index % 2 == 0 ? "tablerow tablerowColor Flex" : "tablerow Flex"}>{user.finished == 1 ? <a href={"/reportPage?enro_id=" + user.id + "&exam_id=" + user.exam_id  + "&isAdmin=" + "1"}  style={{margin: "auto",}} className="btn btn-primary">Show Report</a>: <div style={{margin: "auto", background: "#79B934", border: "0", color: "white", cursor: "default"}} className="btn" >No Report Yet</div>}</td>