import Asidebar from "../../../FemilerCom/MainDashboard/Asidebar"
import TopLine from "../../../FemilerCom/MainDashboard/TopLine"
import InfroBoxes from "../../../FemilerCom/MainDashboard/InfroBoxes"
import ContentBoxes from "../../../FemilerCom/MainDashboard/ContentBoxes"
import spacedashboard from "../../../Media/Icons/Dashboard/spacedashboard.png"
import quiz from "../../../Media/Icons/Dashboard/quiz.png"
import wallet from "../../../Media/Icons/Dashboard/wallet.png"
import person from "../../../Media/Icons/Dashboard/person.png"
import settings from "../../../Media/Icons/Dashboard/settings.png"
import logouticon from "../../../Media/Icons/Dashboard/logout.png"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import axios from "axios"
import {appContext} from "../../../App"
import Dashpord from "../../../Componants/Dsche/Individualda/List/Dashpord"
import Enrollments from "../../../Componants/Dsche/Individualda/List/Enrollments"
import Differentiformoation from "../../../Componants/Dsche/Individualda/List/Differentiformoation"
import GetProfileIfro from "../../../Componants/Dsche/Individualda/List/GetProfileIfro"

import Dashpordhr from "../../../Componants/Dsche/HR/List/Dashpord"
import Employees from "../../../Componants/Dsche/HR/List/Employees"
import MyPacks from "../../../Componants/Dsche/HR/List/MyPacks"
import Report from "../../../Componants/Dsche/HR/List/Report"
import Packs from "../../../Componants/Dsche/HR/List/Packs"
import ReportSec from "../../../Componants/Dsche/HR/List/ReportSec/ReportSec"
import HrPayment from "../../../Componants/hrPayment/HrPayment"

import Dashpordadmin from "../../../Componants/Dsche/Admin/List/Dashpord"
// import AddAdmin from "../../../Componants/Dsche/Admin/List/AddAdmin"
import AddPacks from "../../../Componants/Dsche/Admin/List/AddPacks"
import AddTest from "../../../Componants/Dsche/Admin/List/AddTest"
// import EditOldReport from "../../../Componants/Dsche/Admin/List/EditOldReport"
// import EditOldTest from "../../../Componants/Dsche/Admin/List/EditOldTest"
// import EditTemp from "../../../Componants/Dsche/Admin/List/EditTemp"
import Exams from "../../../Componants/Dsche/Admin/List/Exams"
import ReportSecadmin from "../../../Componants/Dsche/Admin/List/ReportSec"
import ShowPacks from "../../../Componants/Dsche/Admin/List/ShowPacks"
import ShowUsers from "../../../Componants/Dsche/Admin/List/ShowUsers"
import Templates from "../../../Componants/Dsche/Admin/List/Templates"
import GetProfileIfroadmin from "../../../Componants/Dsche/Individualda/List/GetProfileIfroadmin"
import ResetPasswordForAdmin from "../../../Componants/ResetPasswordForAdmin/ResetPassForAdmin"
import ResetPassAd from "../../../Componants/Dsche/Admin/List/ResetPassAd"
import ByExam from "../../../Componants/Dsche/Individualda/List/BuyExam"
import BuyExam from "../../../Componants/Dsche/Individualda/List/BuyExam"
import Payment from "../../Payment/Payment"
import "./Dashboard.css"
import WebsiteLogoBlu from "../../../FemilerCom/Logo/WebsiteLogoBlu"
function Individualda() {
    // ind states
    let [noraml, setnoraml] = useState(true);
    let [dashboard, setdashboard] = useState();
    let [myAssessments, setmyAssessments] = useState();
    let [buyAssessmen, setbuyAssessmen] = useState();
    let [profileifro, setprofileifro] = useState();
    let [updateprofile, setupdateprofile] = useState();
    // ind states
    
    // HR states
    let [hrdashboard, sethrdashboard] = useState();
    let [Enrollmentshr, setEnrollments] = useState();
    let [Packages, setPackages] = useState();
    let [myOrders, setmyOrders] = useState();
    let [Employeeshr, setEmployeeshr] = useState();
    let [hrexam, sethrexam] = useState();
    
    // HR states

    // admin states
    let [Dashboardadmin, setDashboardadmin] = useState();
    let [Enrollmentsadmin, setEnrollmentsadmin] = useState();
    let [Users, setUsers] = useState();
    let [Packagesadmin, setPackagesadmin] = useState();
    let [addPackage, setaddPackage] = useState();
    let [Examsadmin, setExams] = useState();
    let [addExam, setaddExam] = useState();
    let [Templatesadmin, setTemplates] = useState();
    
    // admin states


    const isLoggedIn = useContext(appContext);
    useEffect(() => {
        if (isLoggedIn?.userData?.exams?.length > 0) {
            if (!myAssessments) {
                setmyAssessments(true);
                setnoraml(false);
            }
        } else {
            if (noraml !== true) {
                setnoraml(true);
                setmyAssessments(false);
            }
        }
    }, [isLoggedIn]);

    let [toggleBar, settoggleBar] = useState(false);
    let [toggletoggleBar, setogglettoggleBar] = useState(false);
    const toggleBarFun = () => {
        settoggleBar(prevState => !prevState); 
        console.log("Toggle state:", !toggleBar);
    }
    //
    const [individual, setIndividual] = useState(0);
    const [hr, setHr] = useState(0);
    const [links, setLinks] = useState([]);
    const [notSolvedExamsLength, setNotSolvedExamsLength] = useState(0);
    const [allExam, setAllExam] = useState([]);

    const fetchAdmin = async () => {
        try {
            const userResponse = await axios.get("https://api.twindix.com/dashboard/users", {
                headers: { Authorization: "Bearer " + localStorage.token }
                });
            const userData = userResponse.data.payload.data;

            const indArr = Object.values(userData).filter(user => user.type === 2);
            const hrArr = Object.values(userData).filter(user => user.type === 1);

            setIndividual(indArr.length);
            setHr(hrArr.length);
            setLinks(userResponse.data.payload.links);
            const examResponse = await axios.get("https://api.twindix.com/dashboard/enrollments", {
                headers: { Authorization: "Bearer " + localStorage.token }
            });
            const examData = examResponse.data.payload.data;
            const notSolved = examData.filter(exam => exam.finished === "0");
            setNotSolvedExamsLength(notSolved.length);
            setAllExam(examData);
            } catch (error) {
                console.error('Error fetching data', error);
                }
    };

    useEffect(() => {
        if (isLoggedIn.isAdmin !== 2) {
            fetchAdmin();
        }
    }, []);

                    return (
        <div className="Individualda">
            {window.innerWidth < 750 ? 
                <>
                    <div className="DashpoardLogoContaner">
                        <div className="DashpoardContaner"><WebsiteLogoBlu /></div>
                    </div>
                </>
            : ""}
            <div className={toggleBar ? "A Toggle": "A" }  >
            <div className="Asidebar">
                {isLoggedIn.userType === 1 ?  
                <Asidebar 
                // exitBarOnclick={exitBarFun}
                class1="" class2="" class3="" class4="" class5="" class6="" class7="display" class8="display" 
                quickDetilssrc1 = {spacedashboard} quickDetilssrc2={quiz} quickDetilssrc3={wallet} quickDetilssrc4={wallet} quickDetilssrc5={wallet}  quickDetilssrc6={quiz} accDetilssrc1={person} accDetilssrc2={settings} logDetilssrc={logouticon}
                quickDetilstext1 = "Dashboard" quickDetilstext2="Enrollments" quickDetilstext3="Packages" quickDetilstext4="My Orders"  quickDetilstext5="Employees" quickDetilstext6="Exams" accDetilstext1="Update Profile" accDetilstext2=" Profile" logDetilstext="Log Out"
                quickonclcikfun1={() => {
                    sethrdashboard(!hrdashboard)
                    setEnrollments()
                    setPackages()
                    setmyOrders()
                    setEmployeeshr()
                    setupdateprofile()
                    setprofileifro()
                    sethrexam()
                    setnoraml(false)
                    settoggleBar(false)
                }}
                quickonclcikfun2={() => {
                    setEnrollments(!Enrollmentshr)
                    sethrdashboard()
                    setPackages()
                    setmyOrders()
                    setEmployeeshr()
                    setupdateprofile()
                    setprofileifro()
                    setnoraml(false)
                    settoggleBar(false)
                    sethrexam()
                }}
                quickonclcikfun3={() => {
                    setPackages(!Packages)
                    sethrdashboard()
                    setEnrollments()
                    setmyOrders()
                    setEmployeeshr()
                    setupdateprofile()
                    setprofileifro()
                    setnoraml(false)
                    settoggleBar(false)
                    sethrexam()
                }}
                quickonclcikfun4={() => {
                    setmyOrders(!myOrders)
                    sethrdashboard()
                    setEnrollments()
                    setPackages()
                    setEmployeeshr()
                    setupdateprofile()
                    setprofileifro()
                    setnoraml(false)
                    settoggleBar(false)
                    sethrexam()
                }}
                quickonclcikfun5={() => {
                    setEmployeeshr(!Employeeshr)
                    sethrdashboard()
                    setEnrollments()
                    setPackages()
                    setmyOrders()
                    setupdateprofile()
                    setprofileifro()
                    setnoraml(false)
                    settoggleBar(false)
                    sethrexam()
                }}
                quickonclcikfun6={() => {
                    sethrexam(!hrexam)
                    setEmployeeshr()
                    sethrdashboard()
                    setEnrollments()
                    setPackages()
                    setmyOrders()
                    setupdateprofile()
                    setprofileifro()
                    setnoraml(false)
                    settoggleBar(false)
                }}
                acconclcikfun9={() => {
                    setprofileifro(!profileifro)
                    setEmployeeshr()
                    sethrdashboard()
                    setEnrollments()
                    setPackages()
                    setmyOrders()
                    setnoraml(false)
                    settoggleBar(false)
                    sethrexam()
                }}
                acckonclcikfun10={() => {
                    setupdateprofile(!updateprofile)
                    setEmployeeshr()
                    sethrdashboard()
                    setEnrollments()
                    setPackages()
                    setmyOrders()
                    setprofileifro()
                    setnoraml(false)
                    settoggleBar(false)
                    sethrexam()
                }}
                />
                :   
                isLoggedIn.userType === 2 ?
                <Asidebar 
                // exitBarOnclick={exitBarFun}
                class1="" class2="" class3="" class4="display" class5="display" class6="display" class7="display" class8="display" 
                quickDetilssrc1 = {spacedashboard} quickDetilssrc2={quiz} quickDetilssrc3={wallet} accDetilssrc1={person} accDetilssrc2={settings} logDetilssrc={logouticon}
                quickDetilstext1 = "Dashboard" quickDetilstext2="My Assessments" quickDetilstext3="Buy Assessmen" accDetilstext1="Profile" accDetilstext2="Update Profile" logDetilstext="Log Out"
                quickonclcikfun1={() => {
                    setdashboard(!dashboard)
                    setmyAssessments()
                    setbuyAssessmen()
                    setupdateprofile()
                    setprofileifro()
                    setnoraml(false)
                    settoggleBar(false)
                }}
                quickonclcikfun2={() => {
                    setdashboard()
                    setmyAssessments(!myAssessments)
                    setbuyAssessmen()
                    setupdateprofile()
                    setprofileifro()
                    setnoraml(false)
                    settoggleBar(false)
                }}
                quickonclcikfun3={() => {
                    setbuyAssessmen(!buyAssessmen)
                    setdashboard()
                    setmyAssessments()
                    setupdateprofile()
                    setprofileifro()
                    setnoraml(false)
                    settoggleBar(false)
                }}
                acconclcikfun9={() => {
                    setprofileifro(!profileifro)
                    setupdateprofile()
                    setbuyAssessmen()
                    setdashboard()
                    setmyAssessments()
                    setnoraml(false)
                    settoggleBar(false)
                }}
                acckonclcikfun10={() => {
                    setupdateprofile(!updateprofile)
                    setbuyAssessmen()
                    setdashboard()
                    setmyAssessments()
                    setprofileifro()
                    setnoraml(false)
                    settoggleBar(false)
                }}
                />
                :             
                <Asidebar 
                // exitBarOnclick={exitBarFun}
                    quickDetilssrc1 = {spacedashboard} quickDetilssrc2={quiz} quickDetilssrc3={wallet} quickDetilssrc4={wallet} quickDetilssrc5={wallet} quickDetilssrc6={wallet} quickDetilssrc7={wallet} quickDetilssrc8={wallet} accDetilssrc1={person} accDetilssrc2={settings} logDetilssrc={logouticon}
                    quickDetilstext1 = "Dashboard" quickDetilstext2="Enrollments" quickDetilstext3="Users" quickDetilstext4="Packages" quickDetilstext5="Add Package" quickDetilstext6="Exams" quickDetilstext7="Add Exam" quickDetilstext8="Templates" accDetilstext1="Profile" accDetilstext2="Update Profile" logDetilstext="Log Out"
                    quickonclcikfun1={() => {
                        setDashboardadmin(!Dashboardadmin)
                        setEnrollmentsadmin()
                        setPackagesadmin()
                        setExams()
                        setaddExam()
                        setUsers()
                        setaddPackage()
                        setTemplates()
                        setupdateprofile()
                        setprofileifro()
                        setnoraml(false)
                        settoggleBar(false)
                    }}
                    quickonclcikfun2={() => {
                        setEnrollmentsadmin(!Enrollmentsadmin)
                        setDashboardadmin()
                        setPackagesadmin()
                        setExams()
                        setaddExam()
                        setUsers()
                        setaddPackage()
                        setTemplates()
                        setupdateprofile()
                        setprofileifro()
                        setnoraml(false)
                        settoggleBar(false)
                    }}
                    quickonclcikfun3={() => {
                        setUsers(!Users)
                        setPackagesadmin()
                        setDashboardadmin()
                        setEnrollmentsadmin()
                        setExams()
                        setaddExam()
                        setaddPackage()
                        setTemplates()
                        setupdateprofile()
                        setprofileifro()
                        setnoraml(false)
                        settoggleBar(false)
                    }}
                    quickonclcikfun4={() => {
                        setPackagesadmin(!Packagesadmin)
                        setExams()
                        setDashboardadmin()
                        setEnrollmentsadmin()
                        setaddExam()
                        setUsers()
                        setaddPackage()
                        setTemplates()
                        setupdateprofile()
                        setprofileifro()
                        setnoraml(false)
                        settoggleBar(false)
                    }}
                    quickonclcikfun5={() => {
                        setaddPackage(!addPackage)
                        setaddExam()
                        setDashboardadmin()
                        setEnrollmentsadmin()
                        setPackagesadmin()
                        setExams()
                        setUsers()
                        setTemplates()
                        setupdateprofile()
                        setprofileifro()
                        setnoraml(false)
                        settoggleBar(false)
                    }}
                    quickonclcikfun6={() => {
                        setExams(!Examsadmin)
                        setaddPackage()
                        setDashboardadmin()
                        setEnrollmentsadmin()
                        setPackagesadmin()
                        setUsers()
                        setaddExam()
                        setTemplates()
                        setupdateprofile()
                        setprofileifro()
                        setnoraml(false)
                        settoggleBar(false)
                    }}
                    quickonclcikfun7={() => {
                        setaddExam(!addExam)
                        setTemplates()
                        setDashboardadmin()
                        setEnrollmentsadmin()
                        setPackagesadmin()
                        setExams()
                        setUsers()
                        setaddPackage()
                        setupdateprofile()
                        setprofileifro()
                        setnoraml(false)
                        settoggleBar(false)
                    }}
                    quickonclcikfun8={() => {
                        setTemplates(!Templatesadmin)
                        setUsers()
                        setaddExam()
                        setDashboardadmin()
                        setEnrollmentsadmin()
                        setPackagesadmin()
                        setExams()
                        setaddPackage()
                        setupdateprofile()
                        setprofileifro()
                        setnoraml(false)
                        settoggleBar(false)
                    }}
                    acconclcikfun9={() => {
                        setprofileifro(!profileifro)
                        setaddExam()
                        setDashboardadmin()
                        setEnrollmentsadmin()
                        setPackagesadmin()
                        setExams()
                        setUsers()
                        setaddPackage()
                        setTemplates()
                        setnoraml(false)
                        settoggleBar(false)
                    }}
                    acckonclcikfun10={() => {
                        setupdateprofile(!updateprofile)
                        setaddExam()
                        setDashboardadmin()
                        setEnrollmentsadmin()
                        setPackagesadmin()
                        setExams()
                        setUsers()
                        setTemplates()
                        setaddPackage()
                        setprofileifro()
                        setnoraml(false)
                        settoggleBar(false)
                    }}
                />
                }
            </div>
            </div>
            <div className={isLoggedIn.userType === 1 ? "B" : isLoggedIn.userType === 2 ? "expect" : "B"}>
            <div className="TopLine">
                <TopLine closebar={toggleBar} toggleBarOnclick={toggleBarFun} Name={isLoggedIn.userName} 
                Type={isLoggedIn.userType === 1 ? "Hr" : "Individual"} />
            </div>
                {isLoggedIn.userType === 1 ?
                    <div className="InfroBoxes">
                        <InfroBoxes infor1="individual No." infor2={individual} infor3="HR no." 
                        infor4={hr} infor5="Assessment No." infor6={allExam.length} infor7="Assessment Not Solved No." infor8={notSolvedExamsLength} />
                    </div>
                :
                isLoggedIn.userType === 2 ? 
                    ""
                :
                <div className="InfroBoxes">
                    <InfroBoxes infor1="individual No." infor2={individual} infor3="HR no." 
                    infor4={hr} infor5=" assessment" infor6={allExam.length} infor7="Assessment Not Solved No." infor8={notSolvedExamsLength} />
                </div>
                }
            <div className="ContentBoxes">
            {isLoggedIn.userType === 1 ?  
                <ContentBoxes 
                quickcontent1 = { hrdashboard && <Dashpordhr />}
                quickcontent2 = {Enrollmentshr && <ReportSec />}
                quickcontent3 = {Packages && <Packs/>}
                quickcontent4 = {myOrders && <MyPacks />}
                quickcontent5 = {Employeeshr && <Employees />}
                quickcontent6 = {updateprofile && <Differentiformoation />}
                quickcontent8 = { hrexam && <HrPayment />}
                quickcontent7 = {profileifro && <GetProfileIfro />}
                quickcontent11 = { noraml && <Dashpordhr />}
                />
                :   
                isLoggedIn.userType === 2 ?
                <ContentBoxes 
                quickcontent1 = { dashboard && <Dashpord />}
                quickcontent2 = {myAssessments && <Enrollments />}
                quickcontent5 = {buyAssessmen && <Payment />}
                quickcontent3 = {profileifro && <GetProfileIfro />}
                quickcontent4 = {updateprofile && <Differentiformoation />}
                quickcontent11 = { noraml && <Dashpord />}
                />
                :             
                <ContentBoxes 
                quickcontent1 = { Dashboardadmin && <Dashpordadmin />}
                quickcontent2 = {Enrollmentsadmin && <ReportSecadmin />}
                quickcontent3 = {Users && <ShowUsers />}
                quickcontent4 = {Packagesadmin && <ShowPacks />}
                quickcontent5 = { noraml && <Dashpordadmin />}
                quickcontent6 = {Examsadmin && <Exams />}
                quickcontent7 = {addExam &&<AddTest />}
                quickcontent8 = {Templatesadmin && <Templates/>}
                quickcontent9 = {updateprofile && <ResetPassAd />}
                quickcontent10 = {profileifro && <GetProfileIfroadmin />}
                quickcontent11 = {addPackage && <AddPacks />}
                />
                }
            </div>
            </div>
        </div>
    )
}
export default Individualda
