import groups from "../../Media/Icons/Dashboard/group.png"
import quiz from "../../Media/Icons/Dashboard/exam.png"
import trending from "../../Media/Icons/Dashboard/trend.png"
import wallet from "../../Media/Icons/Dashboard/wallett.png"
import { useEffect, useState } from "react"
import axios from "axios"
function InfroBoxes (props) {
    return (
        <>
            <div className="Infroboxes">
                <div className="InfroBoxe">
                    <div className="Left">
                        <div><img style={{width: "80%", height: "80%"}} src={groups} alt="icon"/></div>
                    </div>
                    <div className="Right">
                        <div>{props.infor1}</div>
                        <div>{props.infor2}</div>
                    </div>
                </div>
                <div className="InfroBoxe">
                    <div className="Left">
                        <div><img  style={{width: "80%", height: "80%"}} src={quiz} alt="icon"/></div>
                    </div>
                    <div className="Right">
                        <div>{props.infor3}</div>
                        <div>{props.infor4}</div>
                    </div>
                </div>
                <div className="InfroBoxe">
                    <div className="Left">
                        <div><img  style={{width: "80%", height: "80%"}} src={trending} alt="icon"/></div>
                    </div>
                    <div className="Right">
                        <div>{props.infor5}</div>
                        <div>{props.infor6}</div>
                    </div>
                </div>
                <div className="InfroBoxe">
                    <div className="Left">
                        <div><img  style={{width: "80%", height: "80%"}} src={wallet} alt="icon"/></div>
                    </div>
                    <div className="Right">
                        <div>{props.infor7}</div>
                        <div>{props.infor8}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InfroBoxes