import axios from "axios";
import { useState } from "react";
import LogoBlu from "../../../FemilerCom/Logo/SLogoBlu";

const Invite = () => {
    const [inviteNew, setInviteNew] = useState(false);
    const [invite, setInvite] = useState(false);
    const [msg, setMsg] = useState(null);
    const [errMsg, setErrMsg] = useState(null);

    const inviteUser = (e) => {
        e.preventDefault();

        const inviteData = inviteNew
            ? {
                  name: e.target["name"].value,
                  email: e.target["email"].value,
                  order_id: e.target["order_id"].value,
              }
            : {
                  user_id: e.target["user_id"].value,
                  order_id: e.target["order_id"].value,
              };

        axios
            .post("https://api.twindix.com/invite", inviteData, {
                headers: {
                    Authorization: "Bearer " + localStorage.token,
                },
            })
            .then((res) => {
                setMsg(res.data.message);
                setErrMsg(null);
                e.target.reset();
            })
            .catch((error) => {
                setErrMsg(error.response.data.message);
                setMsg(null);
            });
    };

    return (
        <div className="Invite" style={{position: "relative", top: "40px"}}>
            <div style={{ width: "215px", margin: "auto" }} className="InviteLogoCon">
                <LogoBlu />
            </div>
            <div className="InviteCon d-flex flex-column">
                <div className="d-flex justify-content-center">
                    <button
                        onClick={() => {
                            setInvite(false);
                            setInviteNew(true);
                        }}
                        className="btn btn-primary m-1"
                    >
                        Invite New User
                    </button>
                    <button
                        onClick={() => {
                            setInvite(true);
                            setInviteNew(false);
                        }}
                        className="btn btn-primary m-1"
                    >
                        Invite Existing User
                    </button>
                </div>
                {inviteNew && (
                    <form onSubmit={inviteUser} className="InviterForm d-flex flex-column justify-content-center align-items-center">
                        <input type="text" name="name" placeholder="Name" required />
                        <input type="email" name="email" placeholder="Email" required />
                        <input type="number" name="order_id" placeholder="Order Id" required />
                        <button type="submit" className="btn btn-success">
                            Invite User
                        </button>
                    </form>
                )}
                {invite && (
                    <form onSubmit={inviteUser} className="InviterForm d-flex flex-column justify-content-center align-items-center">
                        <input type="number" name="user_id" placeholder="User Id" required />
                        <input type="number" name="order_id" placeholder="Order Id" required />
                        <button type="submit" className="btn btn-success">
                            Invite User
                        </button>
                    </form>
                )}
                {errMsg && <div className="fw-bold text-danger">{errMsg}</div>}
                {msg && <div className="fw-bold text-success">{msg}</div>}
            </div>
        </div>
    );
};

export default Invite;
