import axios from "axios";
import { error } from "pdf-lib";
import { useState } from "react";

const EditTemp = () => {
    let [tempData, setTempData] = useState(null)
    let [msg, setMsg] = useState(null)
    let tempId = new URLSearchParams(document.location.search).get("temp_id");
    let tempName = new URLSearchParams(document.location.search).get("temp_name");

    function handleChange(e) {
        
        let {name,value,type} = e.target;
    if(type == "file") {
        setTempData((prevData) => ({
            ...prevData,
            [name]: e.target.files[0],
            }));
    } 
    else setTempData((prevData) => ({ ...prevData, [name] : value}));
    }
    function handleSubmit(e) {
        e.preventDefault();
        let newFormData = new FormData();
        if(tempData.html) {
            
            newFormData.append("html", tempData.html);
        }
        newFormData.append("name", tempData.name);
        if(tempData.copyContent) {
            newFormData.append("references", tempData.copyContent);
        }

        axios.post("https://api.twindix.com/dashboard/templates/"+tempId+"?_method=put", newFormData, {headers : {
            Authorization: "Bearer " + localStorage.token
        }}).then(res => {
            setMsg(res.data.message)
            console.log(res)
        }).catch((error) => {
            console.error("Error fetching exam data:", error);
          });
    }
    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center align-items-center w-50">
            <input onChange={handleChange} type="text" defaultValue={tempName} name="name" placeholder="Template Name" />
            add template excel
            <input onChange={handleChange} type="file" name="copyContent" id="" />
            add html template
            <input onChange={handleChange} type="file" name="html" id="" />
            <button className="btn btn-primary" type="submit">Submit</button>
            <p className="text-success fw-bold text-center">
                {msg}
            </p>
        </form>
    )
}

export default EditTemp;