import { useContext, useEffect, useState } from "react"
import { repoCon } from "../ReportCom"


function Bidtetxt () {
    const repoConn = useContext(repoCon)

    return (
        <>
            <span>
                    {
                        Number(repoConn.answers["111"]) +
                        Number(repoConn.answers["112"]) +
                        Number(repoConn.answers["113"]) +
                        Number(repoConn.answers["114"]) +
                        Number(repoConn.answers["115"]) +
                        Number(repoConn.answers["116"]) +
                        Number(repoConn.answers["117"]) +
                        Number(repoConn.answers["118"]) +
                        Number(repoConn.answers["119"]) >= 5 &&
                        Number(repoConn.answers["211"]) +
                        Number(repoConn.answers["212"]) +
                        Number(repoConn.answers["213"]) +
                        Number(repoConn.answers["214"]) +
                        Number(repoConn.answers["215"]) +
                        Number(repoConn.answers["216"]) +
                        Number(repoConn.answers["217"]) +
                        Number(repoConn.answers["218"]) +
                        Number(repoConn.answers["219"]) >= 5 &&
                        Number(repoConn.answers["311"]) +
                        Number(repoConn.answers["312"]) +
                        Number(repoConn.answers["313"]) +
                        Number(repoConn.answers["314"]) +
                        Number(repoConn.answers["315"]) +
                        Number(repoConn.answers["316"]) +
                        Number(repoConn.answers["317"]) +
                        Number(repoConn.answers["318"]) +
                        Number(repoConn.answers["319"]) >= 5 ? repoConn.referencess[18]["Ar content"]  : 
                        Number(repoConn.answers["111"]) +
                        Number(repoConn.answers["112"]) +
                        Number(repoConn.answers["113"]) +
                        Number(repoConn.answers["114"]) +
                        Number(repoConn.answers["115"]) +
                        Number(repoConn.answers["116"]) +
                        Number(repoConn.answers["117"]) +
                        Number(repoConn.answers["118"]) +
                        Number(repoConn.answers["119"]) >= 5 &&
                        Number(repoConn.answers["211"]) +
                        Number(repoConn.answers["212"]) +
                        Number(repoConn.answers["213"]) +
                        Number(repoConn.answers["214"]) +
                        Number(repoConn.answers["215"]) +
                        Number(repoConn.answers["216"]) +
                        Number(repoConn.answers["217"]) +
                        Number(repoConn.answers["218"]) +
                        Number(repoConn.answers["219"]) >= 5 &&
                        Number(repoConn.answers["311"]) +
                        Number(repoConn.answers["312"]) +
                        Number(repoConn.answers["313"]) +
                        Number(repoConn.answers["314"]) +
                        Number(repoConn.answers["315"]) +
                        Number(repoConn.answers["316"]) +
                        Number(repoConn.answers["317"]) +
                        Number(repoConn.answers["318"]) +
                        Number(repoConn.answers["319"]) <= 4 ? repoConn.referencess[19]["Ar content"] : 
                        Number(repoConn.answers["111"]) +
                        Number(repoConn.answers["112"]) +
                        Number(repoConn.answers["113"]) +
                        Number(repoConn.answers["114"]) +
                        Number(repoConn.answers["115"]) +
                        Number(repoConn.answers["116"]) +
                        Number(repoConn.answers["117"]) +
                        Number(repoConn.answers["118"]) +
                        Number(repoConn.answers["119"]) >= 5 &&
                        Number(repoConn.answers["211"]) +
                        Number(repoConn.answers["212"]) +
                        Number(repoConn.answers["213"]) +
                        Number(repoConn.answers["214"]) +
                        Number(repoConn.answers["215"]) +
                        Number(repoConn.answers["216"]) +
                        Number(repoConn.answers["217"]) +
                        Number(repoConn.answers["218"]) +
                        Number(repoConn.answers["219"]) <= 4 &&
                        Number(repoConn.answers["311"]) +
                        Number(repoConn.answers["312"]) +
                        Number(repoConn.answers["313"]) +
                        Number(repoConn.answers["314"]) +
                        Number(repoConn.answers["315"]) +
                        Number(repoConn.answers["316"]) +
                        Number(repoConn.answers["317"]) +
                        Number(repoConn.answers["318"]) +
                        Number(repoConn.answers["319"]) >= 5 ? repoConn.referencess[20]["Ar content"]  : 
                        Number(repoConn.answers["111"]) +
                        Number(repoConn.answers["112"]) +
                        Number(repoConn.answers["113"]) +
                        Number(repoConn.answers["114"]) +
                        Number(repoConn.answers["115"]) +
                        Number(repoConn.answers["116"]) +
                        Number(repoConn.answers["117"]) +
                        Number(repoConn.answers["118"]) +
                        Number(repoConn.answers["119"]) >= 5 &&
                        Number(repoConn.answers["211"]) +
                        Number(repoConn.answers["212"]) +
                        Number(repoConn.answers["213"]) +
                        Number(repoConn.answers["214"]) +
                        Number(repoConn.answers["215"]) +
                        Number(repoConn.answers["216"]) +
                        Number(repoConn.answers["217"]) +
                        Number(repoConn.answers["218"]) +
                        Number(repoConn.answers["219"]) <= 4 &&
                        Number(repoConn.answers["311"]) +
                        Number(repoConn.answers["312"]) +
                        Number(repoConn.answers["313"]) +
                        Number(repoConn.answers["314"]) +
                        Number(repoConn.answers["315"]) +
                        Number(repoConn.answers["316"]) +
                        Number(repoConn.answers["317"]) +
                        Number(repoConn.answers["318"]) +
                        Number(repoConn.answers["319"]) <= 4 ? repoConn.referencess[21]["Ar content"] :
                        Number(repoConn.answers["111"]) +
                        Number(repoConn.answers["112"]) +
                        Number(repoConn.answers["113"]) +
                        Number(repoConn.answers["114"]) +
                        Number(repoConn.answers["115"]) +
                        Number(repoConn.answers["116"]) +
                        Number(repoConn.answers["117"]) +
                        Number(repoConn.answers["118"]) +
                        Number(repoConn.answers["119"]) <= 4 &&
                        Number(repoConn.answers["211"]) +
                        Number(repoConn.answers["212"]) +
                        Number(repoConn.answers["213"]) +
                        Number(repoConn.answers["214"]) +
                        Number(repoConn.answers["215"]) +
                        Number(repoConn.answers["216"]) +
                        Number(repoConn.answers["217"]) +
                        Number(repoConn.answers["218"]) +
                        Number(repoConn.answers["219"]) >= 5 &&
                        Number(repoConn.answers["311"]) +
                        Number(repoConn.answers["312"]) +
                        Number(repoConn.answers["313"]) +
                        Number(repoConn.answers["314"]) +
                        Number(repoConn.answers["315"]) +
                        Number(repoConn.answers["316"]) +
                        Number(repoConn.answers["317"]) +
                        Number(repoConn.answers["318"]) +
                        Number(repoConn.answers["319"]) >= 5 ? repoConn.referencess[22]["Ar content"]: 
                        Number(repoConn.answers["111"]) +
                        Number(repoConn.answers["112"]) +
                        Number(repoConn.answers["113"]) +
                        Number(repoConn.answers["114"]) +
                        Number(repoConn.answers["115"]) +
                        Number(repoConn.answers["116"]) +
                        Number(repoConn.answers["117"]) +
                        Number(repoConn.answers["118"]) +
                        Number(repoConn.answers["119"]) <= 4 &&
                        Number(repoConn.answers["211"]) +
                        Number(repoConn.answers["212"]) +
                        Number(repoConn.answers["213"]) +
                        Number(repoConn.answers["214"]) +
                        Number(repoConn.answers["215"]) +
                        Number(repoConn.answers["216"]) +
                        Number(repoConn.answers["217"]) +
                        Number(repoConn.answers["218"]) +
                        Number(repoConn.answers["219"]) >= 5 &&
                        Number(repoConn.answers["311"]) +
                        Number(repoConn.answers["312"]) +
                        Number(repoConn.answers["313"]) +
                        Number(repoConn.answers["314"]) +
                        Number(repoConn.answers["315"]) +
                        Number(repoConn.answers["316"]) +
                        Number(repoConn.answers["317"]) +
                        Number(repoConn.answers["318"]) +
                        Number(repoConn.answers["319"]) <= 4 ? repoConn.referencess[23]["Ar content"] : 
                        Number(repoConn.answers["111"]) +
                        Number(repoConn.answers["112"]) +
                        Number(repoConn.answers["113"]) +
                        Number(repoConn.answers["114"]) +
                        Number(repoConn.answers["115"]) +
                        Number(repoConn.answers["116"]) +
                        Number(repoConn.answers["117"]) +
                        Number(repoConn.answers["118"]) +
                        Number(repoConn.answers["119"]) <= 4 &&
                        Number(repoConn.answers["211"]) +
                        Number(repoConn.answers["212"]) +
                        Number(repoConn.answers["213"]) +
                        Number(repoConn.answers["214"]) +
                        Number(repoConn.answers["215"]) +
                        Number(repoConn.answers["216"]) +
                        Number(repoConn.answers["217"]) +
                        Number(repoConn.answers["218"]) +
                        Number(repoConn.answers["219"]) <= 4 &&
                        Number(repoConn.answers["311"]) +
                        Number(repoConn.answers["312"]) +
                        Number(repoConn.answers["313"]) +
                        Number(repoConn.answers["314"]) +
                        Number(repoConn.answers["315"]) +
                        Number(repoConn.answers["316"]) +
                        Number(repoConn.answers["317"]) +
                        Number(repoConn.answers["318"]) +
                        Number(repoConn.answers["319"]) >= 5 ? repoConn.referencess[24]["Ar content"] : 
                        Number(repoConn.answers["111"]) +
                        Number(repoConn.answers["112"]) +
                        Number(repoConn.answers["113"]) +
                        Number(repoConn.answers["114"]) +
                        Number(repoConn.answers["115"]) +
                        Number(repoConn.answers["116"]) +
                        Number(repoConn.answers["117"]) +
                        Number(repoConn.answers["118"]) +
                        Number(repoConn.answers["119"]) <= 4 &&
                        Number(repoConn.answers["211"]) +
                        Number(repoConn.answers["212"]) +
                        Number(repoConn.answers["213"]) +
                        Number(repoConn.answers["214"]) +
                        Number(repoConn.answers["215"]) +
                        Number(repoConn.answers["216"]) +
                        Number(repoConn.answers["217"]) +
                        Number(repoConn.answers["218"]) +
                        Number(repoConn.answers["219"]) <= 4 &&
                        Number(repoConn.answers["311"]) +
                        Number(repoConn.answers["312"]) +
                        Number(repoConn.answers["313"]) +
                        Number(repoConn.answers["314"]) +
                        Number(repoConn.answers["315"]) +
                        Number(repoConn.answers["316"]) +
                        Number(repoConn.answers["317"]) +
                        Number(repoConn.answers["318"]) +
                        Number(repoConn.answers["319"]) <= 4 ? repoConn.referencess[25]["Ar content"] : ""
                        }
                    </span>
    <br />
                    <span>
                    {
                        Number(repoConn.answers["121"]) +
                        Number(repoConn.answers["122"]) +
                        Number(repoConn.answers["123"]) +
                        Number(repoConn.answers["124"]) +
                        Number(repoConn.answers["125"]) +
                        Number(repoConn.answers["126"]) +
                        Number(repoConn.answers["127"]) +
                        Number(repoConn.answers["128"]) +
                        Number(repoConn.answers["129"]) >= 5 &&
                        Number(repoConn.answers["221"]) +
                        Number(repoConn.answers["222"]) +
                        Number(repoConn.answers["223"]) +
                        Number(repoConn.answers["224"]) +
                        Number(repoConn.answers["225"]) +
                        Number(repoConn.answers["226"]) +
                        Number(repoConn.answers["227"]) +
                        Number(repoConn.answers["228"]) +
                        Number(repoConn.answers["229"]) >= 5 &&
                        Number(repoConn.answers["321"]) +
                        Number(repoConn.answers["322"]) +
                        Number(repoConn.answers["323"]) +
                        Number(repoConn.answers["324"]) +
                        Number(repoConn.answers["325"]) +
                        Number(repoConn.answers["326"]) +
                        Number(repoConn.answers["327"]) +
                        Number(repoConn.answers["328"]) +
                        Number(repoConn.answers["329"]) >= 5 ? repoConn.referencess[26]["Ar content"] : 
                        Number(repoConn.answers["121"]) +
                        Number(repoConn.answers["122"]) +
                        Number(repoConn.answers["123"]) +
                        Number(repoConn.answers["124"]) +
                        Number(repoConn.answers["125"]) +
                        Number(repoConn.answers["126"]) +
                        Number(repoConn.answers["127"]) +
                        Number(repoConn.answers["128"]) +
                        Number(repoConn.answers["129"]) >= 5 &&
                        Number(repoConn.answers["221"]) +
                        Number(repoConn.answers["222"]) +
                        Number(repoConn.answers["223"]) +
                        Number(repoConn.answers["224"]) +
                        Number(repoConn.answers["225"]) +
                        Number(repoConn.answers["226"]) +
                        Number(repoConn.answers["227"]) +
                        Number(repoConn.answers["228"]) +
                        Number(repoConn.answers["229"]) >= 5 &&
                        Number(repoConn.answers["321"]) +
                        Number(repoConn.answers["322"]) +
                        Number(repoConn.answers["323"]) +
                        Number(repoConn.answers["324"]) +
                        Number(repoConn.answers["325"]) +
                        Number(repoConn.answers["326"]) +
                        Number(repoConn.answers["327"]) +
                        Number(repoConn.answers["328"]) +
                        Number(repoConn.answers["329"]) <= 4 ? repoConn.referencess[27]["Ar content"] : 
                        Number(repoConn.answers["121"]) +
                        Number(repoConn.answers["122"]) +
                        Number(repoConn.answers["123"]) +
                        Number(repoConn.answers["124"]) +
                        Number(repoConn.answers["125"]) +
                        Number(repoConn.answers["126"]) +
                        Number(repoConn.answers["127"]) +
                        Number(repoConn.answers["128"]) +
                        Number(repoConn.answers["129"]) >= 5 &&
                        Number(repoConn.answers["221"]) +
                        Number(repoConn.answers["222"]) +
                        Number(repoConn.answers["223"]) +
                        Number(repoConn.answers["224"]) +
                        Number(repoConn.answers["225"]) +
                        Number(repoConn.answers["226"]) +
                        Number(repoConn.answers["227"]) +
                        Number(repoConn.answers["228"]) +
                        Number(repoConn.answers["229"]) <= 4 &&
                        Number(repoConn.answers["321"]) +
                        Number(repoConn.answers["322"]) +
                        Number(repoConn.answers["323"]) +
                        Number(repoConn.answers["324"]) +
                        Number(repoConn.answers["325"]) +
                        Number(repoConn.answers["326"]) +
                        Number(repoConn.answers["327"]) +
                        Number(repoConn.answers["328"]) +
                        Number(repoConn.answers["329"]) >= 5 ? repoConn.referencess[28]["Ar content"]  : 
                        Number(repoConn.answers["121"]) +
                        Number(repoConn.answers["122"]) +
                        Number(repoConn.answers["123"]) +
                        Number(repoConn.answers["124"]) +
                        Number(repoConn.answers["125"]) +
                        Number(repoConn.answers["126"]) +
                        Number(repoConn.answers["127"]) +
                        Number(repoConn.answers["128"]) +
                        Number(repoConn.answers["129"]) >= 5 &&
                        Number(repoConn.answers["221"]) +
                        Number(repoConn.answers["222"]) +
                        Number(repoConn.answers["223"]) +
                        Number(repoConn.answers["224"]) +
                        Number(repoConn.answers["225"]) +
                        Number(repoConn.answers["226"]) +
                        Number(repoConn.answers["227"]) +
                        Number(repoConn.answers["228"]) +
                        Number(repoConn.answers["229"]) <= 4 &&
                        Number(repoConn.answers["321"]) +
                        Number(repoConn.answers["322"]) +
                        Number(repoConn.answers["323"]) +
                        Number(repoConn.answers["324"]) +
                        Number(repoConn.answers["325"]) +
                        Number(repoConn.answers["326"]) +
                        Number(repoConn.answers["327"]) +
                        Number(repoConn.answers["328"]) +
                        Number(repoConn.answers["329"]) <= 4 ? repoConn.referencess[29]["Ar content"] :
                        Number(repoConn.answers["121"]) +
                        Number(repoConn.answers["122"]) +
                        Number(repoConn.answers["123"]) +
                        Number(repoConn.answers["124"]) +
                        Number(repoConn.answers["125"]) +
                        Number(repoConn.answers["126"]) +
                        Number(repoConn.answers["127"]) +
                        Number(repoConn.answers["128"]) +
                        Number(repoConn.answers["129"]) <= 4 &&
                        Number(repoConn.answers["221"]) +
                        Number(repoConn.answers["222"]) +
                        Number(repoConn.answers["223"]) +
                        Number(repoConn.answers["224"]) +
                        Number(repoConn.answers["225"]) +
                        Number(repoConn.answers["226"]) +
                        Number(repoConn.answers["227"]) +
                        Number(repoConn.answers["228"]) +
                        Number(repoConn.answers["229"]) >= 5 &&
                        Number(repoConn.answers["321"]) +
                        Number(repoConn.answers["322"]) +
                        Number(repoConn.answers["323"]) +
                        Number(repoConn.answers["324"]) +
                        Number(repoConn.answers["325"]) +
                        Number(repoConn.answers["326"]) +
                        Number(repoConn.answers["327"]) +
                        Number(repoConn.answers["328"]) +
                        Number(repoConn.answers["329"]) >= 5 ? repoConn.referencess[30]["Ar content"]: 
                        Number(repoConn.answers["121"]) +
                        Number(repoConn.answers["122"]) +
                        Number(repoConn.answers["123"]) +
                        Number(repoConn.answers["124"]) +
                        Number(repoConn.answers["125"]) +
                        Number(repoConn.answers["126"]) +
                        Number(repoConn.answers["127"]) +
                        Number(repoConn.answers["128"]) +
                        Number(repoConn.answers["129"]) <= 4 &&
                        Number(repoConn.answers["221"]) +
                        Number(repoConn.answers["222"]) +
                        Number(repoConn.answers["223"]) +
                        Number(repoConn.answers["224"]) +
                        Number(repoConn.answers["225"]) +
                        Number(repoConn.answers["226"]) +
                        Number(repoConn.answers["227"]) +
                        Number(repoConn.answers["228"]) +
                        Number(repoConn.answers["229"]) >= 5 &&
                        Number(repoConn.answers["321"]) +
                        Number(repoConn.answers["322"]) +
                        Number(repoConn.answers["323"]) +
                        Number(repoConn.answers["324"]) +
                        Number(repoConn.answers["325"]) +
                        Number(repoConn.answers["326"]) +
                        Number(repoConn.answers["327"]) +
                        Number(repoConn.answers["328"]) +
                        Number(repoConn.answers["329"]) <= 4 ? repoConn.referencess[31]["Ar content"] : 
                        Number(repoConn.answers["121"]) +
                        Number(repoConn.answers["122"]) +
                        Number(repoConn.answers["123"]) +
                        Number(repoConn.answers["124"]) +
                        Number(repoConn.answers["125"]) +
                        Number(repoConn.answers["126"]) +
                        Number(repoConn.answers["127"]) +
                        Number(repoConn.answers["128"]) +
                        Number(repoConn.answers["129"]) <= 4 &&
                        Number(repoConn.answers["221"]) +
                        Number(repoConn.answers["222"]) +
                        Number(repoConn.answers["223"]) +
                        Number(repoConn.answers["224"]) +
                        Number(repoConn.answers["225"]) +
                        Number(repoConn.answers["226"]) +
                        Number(repoConn.answers["227"]) +
                        Number(repoConn.answers["228"]) +
                        Number(repoConn.answers["229"]) <= 4 &&
                        Number(repoConn.answers["321"]) +
                        Number(repoConn.answers["322"]) +
                        Number(repoConn.answers["323"]) +
                        Number(repoConn.answers["324"]) +
                        Number(repoConn.answers["325"]) +
                        Number(repoConn.answers["326"]) +
                        Number(repoConn.answers["327"]) +
                        Number(repoConn.answers["328"]) +
                        Number(repoConn.answers["329"]) >= 5 ? repoConn.referencess[32]["Ar content"] : 
                        Number(repoConn.answers["121"]) +
                        Number(repoConn.answers["122"]) +
                        Number(repoConn.answers["123"]) +
                        Number(repoConn.answers["124"]) +
                        Number(repoConn.answers["125"]) +
                        Number(repoConn.answers["126"]) +
                        Number(repoConn.answers["127"]) +
                        Number(repoConn.answers["128"]) +
                        Number(repoConn.answers["129"]) <= 4 &&
                        Number(repoConn.answers["221"]) +
                        Number(repoConn.answers["222"]) +
                        Number(repoConn.answers["223"]) +
                        Number(repoConn.answers["224"]) +
                        Number(repoConn.answers["225"]) +
                        Number(repoConn.answers["226"]) +
                        Number(repoConn.answers["227"]) +
                        Number(repoConn.answers["228"]) +
                        Number(repoConn.answers["229"]) <= 4 &&
                        Number(repoConn.answers["321"]) +
                        Number(repoConn.answers["322"]) +
                        Number(repoConn.answers["323"]) +
                        Number(repoConn.answers["324"]) +
                        Number(repoConn.answers["325"]) +
                        Number(repoConn.answers["326"]) +
                        Number(repoConn.answers["327"]) +
                        Number(repoConn.answers["328"]) +
                        Number(repoConn.answers["329"]) <= 4 ? repoConn.referencess[33]["Ar content"] : ""
                        }
                    </span>
    <br />
                    <span>
                    {   
                        Number(repoConn.answers["131"]) +
                        Number(repoConn.answers["132"]) +
                        Number(repoConn.answers["133"]) +
                        Number(repoConn.answers["134"]) +
                        Number(repoConn.answers["135"]) +
                        Number(repoConn.answers["136"]) +
                        Number(repoConn.answers["137"]) +
                        Number(repoConn.answers["138"]) +
                        Number(repoConn.answers["139"]) >= 5 &&
                        Number(repoConn.answers["231"]) +
                        Number(repoConn.answers["232"]) +
                        Number(repoConn.answers["233"]) +
                        Number(repoConn.answers["234"]) +
                        Number(repoConn.answers["235"]) +
                        Number(repoConn.answers["236"]) +
                        Number(repoConn.answers["237"]) +
                        Number(repoConn.answers["238"]) +
                        Number(repoConn.answers["239"]) >= 5 &&
                        Number(repoConn.answers["331"]) +
                        Number(repoConn.answers["332"]) +
                        Number(repoConn.answers["333"]) +
                        Number(repoConn.answers["334"]) +
                        Number(repoConn.answers["335"]) +
                        Number(repoConn.answers["336"]) +
                        Number(repoConn.answers["337"]) +
                        Number(repoConn.answers["338"]) +
                        Number(repoConn.answers["339"]) >= 5 ? repoConn.referencess[34]["Ar content"] : 
                        Number(repoConn.answers["131"]) +
                        Number(repoConn.answers["132"]) +
                        Number(repoConn.answers["133"]) +
                        Number(repoConn.answers["134"]) +
                        Number(repoConn.answers["135"]) +
                        Number(repoConn.answers["136"]) +
                        Number(repoConn.answers["137"]) +
                        Number(repoConn.answers["138"]) +
                        Number(repoConn.answers["139"]) >= 5 &&
                        Number(repoConn.answers["231"]) +
                        Number(repoConn.answers["232"]) +
                        Number(repoConn.answers["233"]) +
                        Number(repoConn.answers["234"]) +
                        Number(repoConn.answers["235"]) +
                        Number(repoConn.answers["236"]) +
                        Number(repoConn.answers["237"]) +
                        Number(repoConn.answers["238"]) +
                        Number(repoConn.answers["239"]) >= 5 &&
                        Number(repoConn.answers["331"]) +
                        Number(repoConn.answers["332"]) +
                        Number(repoConn.answers["333"]) +
                        Number(repoConn.answers["334"]) +
                        Number(repoConn.answers["335"]) +
                        Number(repoConn.answers["336"]) +
                        Number(repoConn.answers["337"]) +
                        Number(repoConn.answers["338"]) +
                        Number(repoConn.answers["339"]) <= 4 ? repoConn.referencess[35]["Ar content"] : 
                        Number(repoConn.answers["131"]) +
                        Number(repoConn.answers["132"]) +
                        Number(repoConn.answers["133"]) +
                        Number(repoConn.answers["134"]) +
                        Number(repoConn.answers["135"]) +
                        Number(repoConn.answers["136"]) +
                        Number(repoConn.answers["137"]) +
                        Number(repoConn.answers["138"]) +
                        Number(repoConn.answers["139"]) >= 5 &&
                        Number(repoConn.answers["231"]) +
                        Number(repoConn.answers["232"]) +
                        Number(repoConn.answers["233"]) +
                        Number(repoConn.answers["234"]) +
                        Number(repoConn.answers["235"]) +
                        Number(repoConn.answers["236"]) +
                        Number(repoConn.answers["237"]) +
                        Number(repoConn.answers["238"]) +
                        Number(repoConn.answers["239"]) <= 4 &&
                        Number(repoConn.answers["331"]) +
                        Number(repoConn.answers["332"]) +
                        Number(repoConn.answers["333"]) +
                        Number(repoConn.answers["334"]) +
                        Number(repoConn.answers["335"]) +
                        Number(repoConn.answers["336"]) +
                        Number(repoConn.answers["337"]) +
                        Number(repoConn.answers["338"]) +
                        Number(repoConn.answers["339"]) >= 5 ? repoConn.referencess[36]["Ar content"]  : 
                        Number(repoConn.answers["131"]) +
                        Number(repoConn.answers["132"]) +
                        Number(repoConn.answers["133"]) +
                        Number(repoConn.answers["134"]) +
                        Number(repoConn.answers["135"]) +
                        Number(repoConn.answers["136"]) +
                        Number(repoConn.answers["137"]) +
                        Number(repoConn.answers["138"]) +
                        Number(repoConn.answers["139"]) >= 5 &&
                        Number(repoConn.answers["231"]) +
                        Number(repoConn.answers["232"]) +
                        Number(repoConn.answers["233"]) +
                        Number(repoConn.answers["234"]) +
                        Number(repoConn.answers["235"]) +
                        Number(repoConn.answers["236"]) +
                        Number(repoConn.answers["237"]) +
                        Number(repoConn.answers["238"]) +
                        Number(repoConn.answers["239"]) <= 4 &&
                        Number(repoConn.answers["331"]) +
                        Number(repoConn.answers["332"]) +
                        Number(repoConn.answers["333"]) +
                        Number(repoConn.answers["334"]) +
                        Number(repoConn.answers["335"]) +
                        Number(repoConn.answers["336"]) +
                        Number(repoConn.answers["337"]) +
                        Number(repoConn.answers["338"]) +
                        Number(repoConn.answers["339"]) <= 4 ? repoConn.referencess[37]["Ar content"] :
                        Number(repoConn.answers["131"]) +
                        Number(repoConn.answers["132"]) +
                        Number(repoConn.answers["133"]) +
                        Number(repoConn.answers["134"]) +
                        Number(repoConn.answers["135"]) +
                        Number(repoConn.answers["136"]) +
                        Number(repoConn.answers["137"]) +
                        Number(repoConn.answers["138"]) +
                        Number(repoConn.answers["139"]) <= 4 &&
                        Number(repoConn.answers["231"]) +
                        Number(repoConn.answers["232"]) +
                        Number(repoConn.answers["233"]) +
                        Number(repoConn.answers["234"]) +
                        Number(repoConn.answers["235"]) +
                        Number(repoConn.answers["236"]) +
                        Number(repoConn.answers["237"]) +
                        Number(repoConn.answers["238"]) +
                        Number(repoConn.answers["239"]) >= 5 &&
                        Number(repoConn.answers["331"]) +
                        Number(repoConn.answers["332"]) +
                        Number(repoConn.answers["333"]) +
                        Number(repoConn.answers["334"]) +
                        Number(repoConn.answers["335"]) +
                        Number(repoConn.answers["336"]) +
                        Number(repoConn.answers["337"]) +
                        Number(repoConn.answers["338"]) +
                        Number(repoConn.answers["339"]) >= 5 ? repoConn.referencess[38]["Ar content"] : 
                        Number(repoConn.answers["131"]) +
                        Number(repoConn.answers["132"]) +
                        Number(repoConn.answers["133"]) +
                        Number(repoConn.answers["134"]) +
                        Number(repoConn.answers["135"]) +
                        Number(repoConn.answers["136"]) +
                        Number(repoConn.answers["137"]) +
                        Number(repoConn.answers["138"]) +
                        Number(repoConn.answers["139"]) <= 4 &&
                        Number(repoConn.answers["231"]) +
                        Number(repoConn.answers["232"]) +
                        Number(repoConn.answers["233"]) +
                        Number(repoConn.answers["234"]) +
                        Number(repoConn.answers["235"]) +
                        Number(repoConn.answers["236"]) +
                        Number(repoConn.answers["237"]) +
                        Number(repoConn.answers["238"]) +
                        Number(repoConn.answers["239"]) >= 5 &&
                        Number(repoConn.answers["331"]) +
                        Number(repoConn.answers["332"]) +
                        Number(repoConn.answers["333"]) +
                        Number(repoConn.answers["334"]) +
                        Number(repoConn.answers["335"]) +
                        Number(repoConn.answers["336"]) +
                        Number(repoConn.answers["337"]) +
                        Number(repoConn.answers["338"]) +
                        Number(repoConn.answers["339"]) <= 4 ? repoConn.referencess[39]["Ar content"] : 
                        Number(repoConn.answers["131"]) +
                        Number(repoConn.answers["132"]) +
                        Number(repoConn.answers["133"]) +
                        Number(repoConn.answers["134"]) +
                        Number(repoConn.answers["135"]) +
                        Number(repoConn.answers["136"]) +
                        Number(repoConn.answers["137"]) +
                        Number(repoConn.answers["138"]) +
                        Number(repoConn.answers["139"]) <= 4 &&
                        Number(repoConn.answers["231"]) +
                        Number(repoConn.answers["232"]) +
                        Number(repoConn.answers["233"]) +
                        Number(repoConn.answers["234"]) +
                        Number(repoConn.answers["235"]) +
                        Number(repoConn.answers["236"]) +
                        Number(repoConn.answers["237"]) +
                        Number(repoConn.answers["238"]) +
                        Number(repoConn.answers["239"]) <= 4 &&
                        Number(repoConn.answers["331"]) +
                        Number(repoConn.answers["332"]) +
                        Number(repoConn.answers["333"]) +
                        Number(repoConn.answers["334"]) +
                        Number(repoConn.answers["335"]) +
                        Number(repoConn.answers["336"]) +
                        Number(repoConn.answers["337"]) +
                        Number(repoConn.answers["338"]) +
                        Number(repoConn.answers["339"]) >= 5 ? repoConn.referencess[40]["Ar content"] : 
                        Number(repoConn.answers["131"]) +
                        Number(repoConn.answers["132"]) +
                        Number(repoConn.answers["133"]) +
                        Number(repoConn.answers["134"]) +
                        Number(repoConn.answers["135"]) +
                        Number(repoConn.answers["136"]) +
                        Number(repoConn.answers["137"]) +
                        Number(repoConn.answers["138"]) +
                        Number(repoConn.answers["139"]) <= 4 &&
                        Number(repoConn.answers["231"]) +
                        Number(repoConn.answers["232"]) +
                        Number(repoConn.answers["233"]) +
                        Number(repoConn.answers["234"]) +
                        Number(repoConn.answers["235"]) +
                        Number(repoConn.answers["236"]) +
                        Number(repoConn.answers["237"]) +
                        Number(repoConn.answers["238"]) +
                        Number(repoConn.answers["239"]) <= 4 &&
                        Number(repoConn.answers["331"]) +
                        Number(repoConn.answers["332"]) +
                        Number(repoConn.answers["333"]) +
                        Number(repoConn.answers["334"]) +
                        Number(repoConn.answers["335"]) +
                        Number(repoConn.answers["336"]) +
                        Number(repoConn.answers["337"]) +
                        Number(repoConn.answers["338"]) +
                        Number(repoConn.answers["339"]) <= 4 ? repoConn.referencess[41]["Ar content"] : "ss"
                        }
                    </span>
    <br/>
    <span>
                    {   
                        Number(repoConn.answers["141"]) +
                        Number(repoConn.answers["142"]) +
                        Number(repoConn.answers["143"]) +
                        Number(repoConn.answers["144"]) +
                        Number(repoConn.answers["145"]) +
                        Number(repoConn.answers["146"]) +
                        Number(repoConn.answers["147"]) +
                        Number(repoConn.answers["148"]) +
                        Number(repoConn.answers["149"]) >= 5 &&
                        Number(repoConn.answers["241"]) +
                        Number(repoConn.answers["242"]) +
                        Number(repoConn.answers["243"]) +
                        Number(repoConn.answers["244"]) +
                        Number(repoConn.answers["245"]) +
                        Number(repoConn.answers["246"]) +
                        Number(repoConn.answers["247"]) +
                        Number(repoConn.answers["248"]) +
                        Number(repoConn.answers["249"]) >= 5 &&
                        Number(repoConn.answers["341"]) +
                        Number(repoConn.answers["342"]) +
                        Number(repoConn.answers["343"]) +
                        Number(repoConn.answers["344"]) +
                        Number(repoConn.answers["345"]) +
                        Number(repoConn.answers["346"]) +
                        Number(repoConn.answers["347"]) +
                        Number(repoConn.answers["348"]) +
                        Number(repoConn.answers["349"]) >= 5 ? repoConn.referencess[42]["Ar content"] : 
                        Number(repoConn.answers["141"]) +
                        Number(repoConn.answers["142"]) +
                        Number(repoConn.answers["143"]) +
                        Number(repoConn.answers["144"]) +
                        Number(repoConn.answers["145"]) +
                        Number(repoConn.answers["146"]) +
                        Number(repoConn.answers["147"]) +
                        Number(repoConn.answers["148"]) +
                        Number(repoConn.answers["149"]) >= 5 &&
                        Number(repoConn.answers["241"]) +
                        Number(repoConn.answers["242"]) +
                        Number(repoConn.answers["243"]) +
                        Number(repoConn.answers["244"]) +
                        Number(repoConn.answers["245"]) +
                        Number(repoConn.answers["246"]) +
                        Number(repoConn.answers["247"]) +
                        Number(repoConn.answers["248"]) +
                        Number(repoConn.answers["249"]) >= 5 &&
                        Number(repoConn.answers["341"]) +
                        Number(repoConn.answers["342"]) +
                        Number(repoConn.answers["343"]) +
                        Number(repoConn.answers["344"]) +
                        Number(repoConn.answers["345"]) +
                        Number(repoConn.answers["346"]) +
                        Number(repoConn.answers["347"]) +
                        Number(repoConn.answers["348"]) +
                        Number(repoConn.answers["349"]) <= 4 ? repoConn.referencess[43]["Ar content"] : 
                        Number(repoConn.answers["141"]) +
                        Number(repoConn.answers["142"]) +
                        Number(repoConn.answers["143"]) +
                        Number(repoConn.answers["144"]) +
                        Number(repoConn.answers["145"]) +
                        Number(repoConn.answers["146"]) +
                        Number(repoConn.answers["147"]) +
                        Number(repoConn.answers["148"]) +
                        Number(repoConn.answers["149"]) >= 5 &&
                        Number(repoConn.answers["241"]) +
                        Number(repoConn.answers["242"]) +
                        Number(repoConn.answers["243"]) +
                        Number(repoConn.answers["244"]) +
                        Number(repoConn.answers["245"]) +
                        Number(repoConn.answers["246"]) +
                        Number(repoConn.answers["247"]) +
                        Number(repoConn.answers["248"]) +
                        Number(repoConn.answers["249"]) <= 4 &&
                        Number(repoConn.answers["341"]) +
                        Number(repoConn.answers["342"]) +
                        Number(repoConn.answers["343"]) +
                        Number(repoConn.answers["344"]) +
                        Number(repoConn.answers["345"]) +
                        Number(repoConn.answers["346"]) +
                        Number(repoConn.answers["347"]) +
                        Number(repoConn.answers["348"]) +
                        Number(repoConn.answers["349"]) >= 5 ? repoConn.referencess[44]["Ar content"]  : 
                        Number(repoConn.answers["141"]) +
                        Number(repoConn.answers["142"]) +
                        Number(repoConn.answers["143"]) +
                        Number(repoConn.answers["144"]) +
                        Number(repoConn.answers["145"]) +
                        Number(repoConn.answers["146"]) +
                        Number(repoConn.answers["147"]) +
                        Number(repoConn.answers["148"]) +
                        Number(repoConn.answers["149"]) >= 5 &&
                        Number(repoConn.answers["241"]) +
                        Number(repoConn.answers["242"]) +
                        Number(repoConn.answers["243"]) +
                        Number(repoConn.answers["244"]) +
                        Number(repoConn.answers["245"]) +
                        Number(repoConn.answers["246"]) +
                        Number(repoConn.answers["247"]) +
                        Number(repoConn.answers["248"]) +
                        Number(repoConn.answers["249"]) <= 4 &&
                        Number(repoConn.answers["341"]) +
                        Number(repoConn.answers["342"]) +
                        Number(repoConn.answers["343"]) +
                        Number(repoConn.answers["344"]) +
                        Number(repoConn.answers["345"]) +
                        Number(repoConn.answers["346"]) +
                        Number(repoConn.answers["347"]) +
                        Number(repoConn.answers["348"]) +
                        Number(repoConn.answers["349"]) <= 4 ? repoConn.referencess[45]["Ar content"] :
                        Number(repoConn.answers["141"]) +
                        Number(repoConn.answers["142"]) +
                        Number(repoConn.answers["143"]) +
                        Number(repoConn.answers["144"]) +
                        Number(repoConn.answers["145"]) +
                        Number(repoConn.answers["146"]) +
                        Number(repoConn.answers["147"]) +
                        Number(repoConn.answers["148"]) +
                        Number(repoConn.answers["149"]) <= 4 &&
                        Number(repoConn.answers["241"]) +
                        Number(repoConn.answers["242"]) +
                        Number(repoConn.answers["243"]) +
                        Number(repoConn.answers["244"]) +
                        Number(repoConn.answers["245"]) +
                        Number(repoConn.answers["246"]) +
                        Number(repoConn.answers["247"]) +
                        Number(repoConn.answers["248"]) +
                        Number(repoConn.answers["249"]) >= 5 &&
                        Number(repoConn.answers["341"]) +
                        Number(repoConn.answers["342"]) +
                        Number(repoConn.answers["343"]) +
                        Number(repoConn.answers["344"]) +
                        Number(repoConn.answers["345"]) +
                        Number(repoConn.answers["346"]) +
                        Number(repoConn.answers["347"]) +
                        Number(repoConn.answers["348"]) +
                        Number(repoConn.answers["349"]) >= 5 ? repoConn.referencess[46]["Ar content"] : 
                        Number(repoConn.answers["141"]) +
                        Number(repoConn.answers["142"]) +
                        Number(repoConn.answers["143"]) +
                        Number(repoConn.answers["144"]) +
                        Number(repoConn.answers["145"]) +
                        Number(repoConn.answers["146"]) +
                        Number(repoConn.answers["147"]) +
                        Number(repoConn.answers["148"]) +
                        Number(repoConn.answers["149"]) <= 4 &&
                        Number(repoConn.answers["241"]) +
                        Number(repoConn.answers["242"]) +
                        Number(repoConn.answers["243"]) +
                        Number(repoConn.answers["244"]) +
                        Number(repoConn.answers["245"]) +
                        Number(repoConn.answers["246"]) +
                        Number(repoConn.answers["247"]) +
                        Number(repoConn.answers["248"]) +
                        Number(repoConn.answers["249"]) >= 5 &&
                        Number(repoConn.answers["341"]) +
                        Number(repoConn.answers["342"]) +
                        Number(repoConn.answers["343"]) +
                        Number(repoConn.answers["344"]) +
                        Number(repoConn.answers["345"]) +
                        Number(repoConn.answers["346"]) +
                        Number(repoConn.answers["347"]) +
                        Number(repoConn.answers["348"]) +
                        Number(repoConn.answers["349"]) <= 4 ? repoConn.referencess[47]["Ar content"] : 
                        Number(repoConn.answers["141"]) +
                        Number(repoConn.answers["142"]) +
                        Number(repoConn.answers["143"]) +
                        Number(repoConn.answers["144"]) +
                        Number(repoConn.answers["145"]) +
                        Number(repoConn.answers["146"]) +
                        Number(repoConn.answers["147"]) +
                        Number(repoConn.answers["148"]) +
                        Number(repoConn.answers["149"]) <= 4 &&
                        Number(repoConn.answers["241"]) +
                        Number(repoConn.answers["242"]) +
                        Number(repoConn.answers["243"]) +
                        Number(repoConn.answers["244"]) +
                        Number(repoConn.answers["245"]) +
                        Number(repoConn.answers["246"]) +
                        Number(repoConn.answers["247"]) +
                        Number(repoConn.answers["248"]) +
                        Number(repoConn.answers["249"]) <= 4 &&
                        Number(repoConn.answers["341"]) +
                        Number(repoConn.answers["342"]) +
                        Number(repoConn.answers["343"]) +
                        Number(repoConn.answers["344"]) +
                        Number(repoConn.answers["345"]) +
                        Number(repoConn.answers["346"]) +
                        Number(repoConn.answers["347"]) +
                        Number(repoConn.answers["348"]) +
                        Number(repoConn.answers["349"]) >= 5 ? repoConn.referencess[48]["Ar content"] : 
                        Number(repoConn.answers["141"]) +
                        Number(repoConn.answers["142"]) +
                        Number(repoConn.answers["143"]) +
                        Number(repoConn.answers["144"]) +
                        Number(repoConn.answers["145"]) +
                        Number(repoConn.answers["146"]) +
                        Number(repoConn.answers["147"]) +
                        Number(repoConn.answers["148"]) +
                        Number(repoConn.answers["149"]) <= 4 &&
                        Number(repoConn.answers["241"]) +
                        Number(repoConn.answers["242"]) +
                        Number(repoConn.answers["243"]) +
                        Number(repoConn.answers["244"]) +
                        Number(repoConn.answers["245"]) +
                        Number(repoConn.answers["246"]) +
                        Number(repoConn.answers["247"]) +
                        Number(repoConn.answers["248"]) +
                        Number(repoConn.answers["249"]) <= 4 &&
                        Number(repoConn.answers["341"]) +
                        Number(repoConn.answers["342"]) +
                        Number(repoConn.answers["343"]) +
                        Number(repoConn.answers["344"]) +
                        Number(repoConn.answers["345"]) +
                        Number(repoConn.answers["346"]) +
                        Number(repoConn.answers["347"]) +
                        Number(repoConn.answers["348"]) +
                        Number(repoConn.answers["349"]) <= 4 ? repoConn.referencess[49]["Ar content"] : "ss"
                        }
                    </span>
        </>
    )
}

export default Bidtetxt