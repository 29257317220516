import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../FemilerCom/Button/Button';
import LogoWhi from "../../Media/Logo/WebsiteLogoWiht.png";
import emailimg from "../../Media/Icons/Forms/icons8-email-26.png";
import "./Reset.css"
function ResetPasswordForAdmin ()  {
  const [email, setEmail] = useState('');
  const [resetSent, setResetSent] = useState(false);
  const [error, setError] = useState();
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleResetSubmit = async (event) => {
    event.preventDefault();

    // Call your backend API to initiate the password reset process
    try {
      const response = await fetch('https://reqres.in/api/users?page=2', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setResetSent(true);
      } else {
        // Handle error, show message to the user
        console.error('Error:', data.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div className='ResetPasswrdForAdmin'>
        <div className='ResetPssContanerForAdmin'>
          <div className="ResetPassLogoForAdmin">
              <div ><img src={LogoWhi} alt="user" /></div>
          </div>
            {error ? <div style={{left: "50%",
            transform: "translate(-50%)"}} className='alert alert-danger position-absolute top-0 text-center '>
              {error}
            </div> : ""}
            <div className="ResePasstBoxForAdmin">
              <div className="ResetPassTextForAdmin">
                  <h1 className="H1tex">Admin Reset Password</h1>
              </div>
              {resetSent ? (
                <div className='alert alert-success'>Password reset instructions sent to your email.</div>
              ) :
              (
              <form className="SupmitFormForAdmin" action="" onSubmit={handleResetSubmit}>
                <div className="InptCont">
                  <div className="ImgAndTilte">
                    <img className="ImgeInput" src={emailimg} alt="ss" />
                    <div className="title">Email</div>
                    <input
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      required
                      className='RegInput'
                    />                        
                  </div>
                </div>
                  <div className="ResetPassBtnsForAdmin">
                  <div className="ToSend" type="submit">
                            <Button Text="Send"  />
                        </div>
                  </div>
              </form>
              )}
          </div> 
        </div>
      </div>
    </>
  );
};

export default ResetPasswordForAdmin;
