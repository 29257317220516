import { Link } from "react-router-dom"
// import  Button  from "../../../../FemilerCom/Button/Button"
import "./ListCom.css"
import { useState } from "react"
import Invite from "../Invite"
function Dashbord () {
    let style = {
        marginLeft : "20%"
    }
    let [invite, setinvite] = useState()
    return (
        <>
            <button style={invite ?{display: "none"} :style } className="AllBtnCenterInd BtnCenterInd" onClick={setinvite}>Invite User to Exam</button>
            {invite ? 
            <Invite />
            :null
            }
        </>
    )
}

export default Dashbord