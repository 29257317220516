import { useContext, useEffect, useState } from "react"
import axios from "axios"
import Badel from "../../Media/imges/WhatsApp Image 2024-01-09 at 12.28.12_84c2f948.jpg"
import "./HrPayment.css"
import { appContext } from "../../App";
function PaymentCom () {
    const [exams, setExams] = useState(null);
    const [purchaseExam, setPurchaseExam] = useState(null);
    const [selectedExam, setselectedExam] = useState(0);
    function buyExam(examId) {

        axios.post("https://api.twindix.com/payment/exam", {exam_id : examId} ,{headers: {
            Authorization: "Bearer " + localStorage.token
        }}).then(res =>{
            setPurchaseExam("redirecting to paypal to complete payment in 5 seconds")
            setTimeout(()=>{
                window.location.replace(res.data.payload.redirect);
            }, 5000)
        })

    }
    useEffect(() => {
        if(exams == null) {
            axios.get("https://api.twindix.com/exams",{headers:{
                Authorization: "Bearer " + localStorage.token
            }, params:{
            }}).then(res => {
                console.log(res.data.payload.data);
                setExams(res.data.payload.data);
            })

        }
    }, [exams])

    const isLoggedIn = useContext(appContext);

    return (
        <>
                {purchaseExam && <div className="alert alert-success position-absolute top-0 m-3">{purchaseExam}</div>}
            <div className="PayRightSection">
                <div className="PaymentContaner">
                    <div className="PaymentTitl"> Assessments</div>
                        <div className="exams">
                        {exams ? exams.map((exam, index) => {
    return (
        exam.id === 66 ? (
            
            <div
                key={exam.id} // Added key prop to uniquely identify each element
                className="ExamContaner exam"
                style={index % 2 === 0 ? { flexDirection: "row" } : { flexDirection: "row-reverse" }}
            >
                <div>
                    <img
                        className="ExamImage"
                        src={exam.cover_image ? exam.cover_image : Badel}
                        alt="exam cover"
                    />
                </div>
                <div>
                    <h2 className="ExamTitle">{exam.title}</h2>
                    <h2 className="Examdescription">
                        {window.innerWidth < 750 ? exam.description.slice(0, 270) : exam.description.slice(0, 370)}
                        {window.innerWidth < 750 ? "..." : ""}
                    </h2>
                    <div className="PriceAndBtncon">
                        <div className="PriceAndBtn">
                            <span>{exam.price}</span>
                            <div>
                                <button className="ExamBtn" onClick={() => buyExam(exam.id)}>
                                    Buy The Assessments
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null // Use null instead of empty string for React components
    );
}) : null}

                        </div>
                </div>
        </div>
        </>
    )
}

export default PaymentCom


// import Button from "../../FemilerCom/Button/Button"
// import  Logowhit from "../../FemilerCom/Logo/WebsiteLogoWiht"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBagShopping } from '@fortawesome/free-solid-svg-icons'
// import "./HrPayment.css"
// function PaymentCom () {
//     return (
//         <>
//         <div className="PaymentPage">
//             <div className="PayLeftSection">
//             <div className="Log">
//             <Logowhit />
//             </div>
//             <div className="PayDetils">
//                     <h3 className="H1Pay">What Is The Test You Want:</h3>
//                     <div className="TilteTest">
//                         <div className="Box"><span className="Text">TWINDIX Individual Assessment</span></div>
//                     </div>
//                 </div>
//                 <div className="FinlDetils">
//                     <div className="FinlDet">You Will Pay 50 Code</div>
//                     <hr/>
//                 </div>
//                 <div className="FinlePay">
//                     <div className="TotalTxt">Total:</div>
//                     <div className="TotalNum">100$</div>
//                 </div>
//             </div>
//             <div className="PayRightSection">
//                 <div className="CodeCount"> 
//                 <h2>Code Count</h2>
//                     <div><span>50</span></div>
//                     <div><span>100</span></div>
//                     <div><span>150</span></div>
//                 </div>
//                 <div className="PaymentContaner">
//                 <div className="PaymentMethod">
//                 <h1 className="H1Pay">Payment Method IS Paypal:</h1>
//                 </div>
//                 <div className="Detils">
//                     <div className="BillingInfo">
//                         <h1 className="H1Payment">Billing Info:</h1>
//                         <div className="BillingInputs">
//                             <label className="LablePay">Name</label>
//                             <input className="PaymentInput" />
//                             <label className="LablePay">Address</label>
//                             <input className="PaymentInput" />
//                             <label className="LablePay">Country</label>
//                             <input className="PaymentInput" />
//                         </div>
//                     </div>
//                     <div className="CreadtInfo">
//                         <h1 className="H1Payment" >Paypal Info:</h1>
//                         <div className="CreadtInputs">
//                             <label className="LablePay">Card Numper</label>
//                             <input className="PaymentInput" />
//                             <label className="LablePay">CardHolder Name</label>
//                             <input className="PaymentInput" />
//                             <label className="LablePay">Expired Date</label>
//                             <input className="PaymentInput" />
//                         </div>
//                     </div>
//                 </div>
//                 <div className="PayBtn">
//                     <Button Text="Pay" />
//                 </div>
//                 </div>
//             </div>
//         </div>
//         </>
//     )
// }

// export default PaymentCom