import { useState } from "react";
import { Link } from "react-router-dom";
import "./ListCom.css";
import Button from "../../../../FemilerCom/Button/Button";
import axios from "axios";

function AddTest() {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
    template_id: "",
    questions: null,
    question_algorithms: null,
    combined_algorithms: null,
    cover_image: null,
  });

  const [fileNames, setFileNames] = useState({
    questions: "",
    question_algorithms: "",
    combined_algorithms: "",
    cover_image: "",
  });

  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFileNames((prevFileNames) => ({
        ...prevFileNames,
        [name]: files[0]?.name || "",
      }));
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  
  //   const formDataToSend = {
  //     title: formData.title,
  //     description: formData.description,
  //     price: formData.price,
  //     template_id: formData.template_id,
  //     questions: formData.questions,
  //     question_algorithms: formData.question_algorithms,
  //     combined_algorithms: formData.combined_algorithms,
  //     cover_image: formData.cover_image
  //   };
  // console.log(formData.questions)
  //   console.log("formDataToSend:", formDataToSend);
  
  //   axios.post('https://api.twindix.com/dashboard/exams', formDataToSend, {
  //     headers: {
  //       Authorization: "Bearer " + localStorage.token,
  //       "Content-Type": "application/json", // Adjust content type if necessary
  //     }
  //   }).then(res => {
  //     console.log("Response:", res);
  //   }).catch(error => {
  //     console.error("Error:", error);
  //     setErrMsg(error.response?.data?.message || "An error occurred");
  //   });
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formDataToSend = {
      title: formData.title,
      description: formData.description,
      price: formData.price,
      template_id: formData.template_id,
      questions: formData.questions, // File object is not included directly in formDataToSend
      question_algorithms: formData.question_algorithms,
      combined_algorithms: formData.combined_algorithms,
      cover_image: formData.cover_image
    };
    console.log(formDataToSend)

    axios.post('https://api.twindix.com/dashboard/exams', formDataToSend, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "multipart/form-data",
                maxBodyLength: Infinity,
            }
    }).then((res) => {
        console.log(res)
        setLoading(false);
}).catch(error => {
  setLoading(false);
  console.error("Error:", error)
              setErrMsg(error.response ? error.response.data.message : "An error occurred");
})
};

  return (
    <>
      <div className={loading ? "LoadingOverlay" : "display"}>
        <span className="LoadingSpinner"></span>
      </div>
      <div className="EditTest">
        <div className="EdittTestCon">
          <h3>Add Test</h3>
          <form onSubmit={handleSubmit}>
            <div style={{ display: "flex" }}>
              <div className="InpuCard">
                <span>Title Test</span>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                />
              </div>
              <div className="InpuCard">
                <span>Price</span>
                <input
                  type="text"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className="InpuCard">
                <span>Description</span>
                <textarea
                  className="TextArea"
                  type="text"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </div>
              <div className="InpuCard">
                <span>Template Id</span>
                <input
                  type="text"
                  name="template_id"
                  value={formData.template_id}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="Flex DragAndDrob">
              <div className="DragFiles">
                Questions
                <input
                  onChange={handleChange}
                  type="file"
                  name="questions"
                  style={{ width: "100%" }}
                />
                {fileNames.questions ? (
                  <span>{fileNames.questions}</span>
                ) : (
                  <span className="DrahMSG">Drag and drop or click to upload</span>
                )}
              </div>
              <div className="DragFiles">
                Question Algorithm
                <input
                  onChange={handleChange}
                  type="file"
                  name="question_algorithms"
                  style={{ width: "100%" }}
                />
                {fileNames.question_algorithms ? (
                  <span>{fileNames.question_algorithms}</span>
                ) : (
                  <span className="DrahMSG">Drag and drop or click to upload</span>
                )}
              </div>
              <div className="DragFiles">
                Combined Algorithms
                <input
                  onChange={handleChange}
                  type="file"
                  name="combined_algorithms"
                  style={{ width: "100%" }}
                />
                {fileNames.combined_algorithms ? (
                  <span>{fileNames.combined_algorithms}</span>
                ) : (
                  <span className="DrahMSG">Drag and drop or click to upload</span>
                )}
              </div>
              <div className="DragFiles">
                Cover Image
                <input
                  onChange={handleChange}
                  type="file"
                  name="cover_image"
                  style={{ width: "100%" }}
                />
                {fileNames.cover_image ? (
                  <span>{fileNames.cover_image}</span>
                ) : (
                  <span className="DrahMSG">Drag and drop or click to upload</span>
                )}
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div className="BtnAddTest">
                <Button Type="submit" Text="Save" />
              </div>
            </div>
          </form>
          {errMsg && <div className="error">{errMsg}</div>}
        </div>
      </div>
    </>
  );
}

export default AddTest;


// import { useState } from "react";
// import { Link } from "react-router-dom";
// import "./ListCom.css";
// import Button from "../../../../FemilerCom/Button/Button";
// import DNDexcil from "../../../../FemilerCom/DNDexcil/DNDexcil";
// import axios from "axios";
// import validator from "validator";

// function validateInputs(formData) {
//     const errors = {};
//     if (validator.isEmpty(formData.title)) {
//         errors.title = "Title is required";
//     }
//     if (validator.isEmpty(formData.description)) {
//         errors.description = "Description is required";
//     }
//     if (!validator.isNumeric(formData.price)) {
//         errors.price = "Price must be a number";
//     }
//     if (!validator.isNumeric(formData.template_id.toString())) {
//         errors.template_id = "Template ID must be a number";
//     }
//     return errors;
// }

// function AddTest() {
//     const [formData, setFormData] = useState({
//         title: '',
//         description: '',
//         price: '',
//         template_id: '',
//         questions: '',
//         question_algorithms: '',
//         combined_algorithms: '',
//         cover_image: ''
//     });
//     const [errMsg, setErrMsg] = useState(null);
//     const [errors, setErrors] = useState({});

//     const handleChange = (e) => {
//         const { name, value, type } = e.target;
//         if (type === "file") {
//             setFormData((prevData) => ({
//                 ...prevData,
//                 [name]: e.target.files[0],
//             }));
//         } else {
//             setFormData((prevData) => ({
//                 ...prevData,
//                 [name]: value,
//             }));
//         }
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         const validationErrors = validateInputs(formData);
//         if (Object.keys(validationErrors).length > 0) {
//             setErrors(validationErrors);
//             return;
//         }
        
//         const formDataToSend = new FormData();
//         formDataToSend.append("title", formData.title);
//         formDataToSend.append("description", formData.description);
//         formDataToSend.append("price", formData.price);
//         formDataToSend.append("template_id", formData.template_id);
//         formDataToSend.append("questions", formData.questions);
//         formDataToSend.append("question_algorithms", formData.question_algorithms);
//         formDataToSend.append("combined_algorithms", formData.combined_algorithms);

//         // Log formDataToSend for debugging
//         for (let [key, value] of formDataToSend.entries()) {
//             console.log(key, value);
//         }

//         axios.post('https://api.twindix.com/dashboard/exams', formDataToSend, {
//             headers: {
//                 Authorization: "Bearer " + localStorage.getItem("token"),
//                 "Content-Type": "multipart/form-data",
//                 maxBodyLength: Infinity,
//             }
//         }).then(res => {
//             console.log(res);
//         }).catch(error => {
//             console.error("Error:", error);
//             setErrMsg(error.response ? error.response.data.message : "An error occurred");
//         });
//     };

//     return (
//         <>
//             <div className="AddTest">
//                 <form onSubmit={handleSubmit} className="w-75">
//                     <h2>Title Test</h2>
//                     <input
//                         type="text"
//                         name="title"
//                         value={formData.title}
//                         onChange={handleChange}
//                     />
//                     {errors.title && <div className="text-danger">{errors.title}</div>}

//                     <h2>Description</h2>
//                     <textarea
//                         type="text"
//                         name="description"
//                         value={formData.description}
//                         onChange={handleChange}
//                     />
//                     {errors.description && <div className="text-danger">{errors.description}</div>}

//                     <h2>Price</h2>
//                     <input
//                         type="text"
//                         name="price"
//                         value={formData.price}
//                         onChange={handleChange}
//                     />
//                     {errors.price && <div className="text-danger">{errors.price}</div>}

//                     <h2>Template Id</h2>
//                     <input
//                         type="number"
//                         name="template_id"
//                         value={formData.template_id}
//                         onChange={handleChange}
//                     />
//                     {errors.template_id && <div className="text-danger">{errors.template_id}</div>}

//                     <div className="Flex">
//                         <div>
//                             <span>Questions</span>
//                             <div className="DragFiles d-flex flex-column">
//                                 <input onChange={handleChange} type="file" name="questions" />
//                             </div>
//                         </div>
//                         <div>
//                             <span>Question Algorithm</span>
//                             <div className="DragFiles d-flex flex-column">
//                                 <input onChange={handleChange} type="file" name="question_algorithms" />
//                             </div>
//                         </div>
//                         <div>
//                             <span>Combined Algorithms</span>
//                             <div className="DragFiles d-flex flex-column">
//                                 <input onChange={handleChange} type="file" name="combined_algorithms" />
//                             </div>
//                         </div>
//                     </div>

//                     <div className="text-danger fw-bold">{errMsg}</div>
//                     <div className="BtnAddTest">
//                         <Button Text="Create Exam" type="submit" />
//                     </div>
//                 </form>
//             </div>
//         </>
//     );
// }

// export default AddTest;
