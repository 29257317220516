import PaymentCom from "../../Componants/Payment/Payment"

function Payment () {
    return (
        <>
        <PaymentCom />
        </>
    )
}

export default Payment