import axios from "axios";
import { useState, useEffect, useContext } from "react"
import { appContext } from "../../../../App";
import { useNavigate } from "react-router";
const Enrollments = () => {
    const [enroll, setEnroll] = useState();
      const [links, setLinks] = useState();
    const [loader, setLoader] = useState();
    const [orderedExam, setOrderedExam] = useState();
    const [alertMsg, setAlertMsg] = useState({code: false, msg: "", classes: ""});
    const [toggleType, setToggleType] = useState(true);
    const [showRepo, setShowRepo] = useState("");
    const {setExamNumber, examNumber, userData} = useContext(appContext);
    let navigate = new useNavigate();
    
    useEffect(() => {
        if(!enroll) {
          axios.get("https://api.twindix.com/enrollments", {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
          setEnroll(res.data.payload.data);
          console.log(res)
          setLinks(res.data.payload.links)
        })
        }

    }, [enroll, ])

    function requestData(url) {
        setLoader(true)
        axios.get(url, {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
          setEnroll(res.data.payload.data);
          setLinks(res.data.payload.links)
          let response = res.data.payload.data;
          setLoader(false)
      
        })
      }
      function sortType(e) {
        let myUsers;
        if(toggleType)  {
          myUsers = enroll.sort((a, b) => a.type - b.type);
          setEnroll(myUsers);
            setToggleType(!toggleType)
        } else {
          myUsers = enroll.sort((a, b) => b.type - a.type);
          setEnroll(myUsers);
            setToggleType(!toggleType)
      
        }
      }


    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className={loader ? "loader" : "d-none"}><span className="spinner-border"></span></div>
          <div className={alertMsg.msg ? "d-block position-fixed top-0" : "d-none"}>
            <div className={alertMsg.classes}>{alertMsg.msg}  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
      </div>
          <table className="Table">
    <thead>
      
      <tr>
        {/* <th style={{cursor: "pointer", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px"}} className="tableheader" onClick={() => requestData("https://api.twindix.com/dashboard/enrollments")} scope="col">id</th> */}
        <th style={{cursor: "pointer"}} className="tableheader" onClick={() => requestData("https://api.twindix.com/dashboard/enrollments?sort=name")} scope="col">Code</th>
        <th style={{cursor: "pointer"}} className="tableheader" onClick={() => requestData("https://api.twindix.com/dashboard/enrollments?sort=email")} scope="col">Finished</th>
        <th style={{cursor: "pointer"}} className="tableheader"  scope="col">Exam Id</th>
        <th style={{cursor: "pointer", borderTopRightRadius: "5px", borderBottomRightRadius: "5px"}} className="tableheader"  scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
    {enroll ? enroll.map((enro, index) => {
      return(
        <>
         <tr key={enro.id}>
        {/* <th className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"} scope="row">{enro.id}</th> */}
        <td className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"}>{enro.code}</td>
        <td className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"}>{enro.finished == 1 ? <span className="text-success">finished</span>: <span className="text-danger">not Finished</span>}</td>
        <td className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"}>{enro.exam_id}</td>
        <td className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"} style={{textAlign: "center"}}>
          {enro.finished == 1 ? "" : <button className="btn btn-primary" style={{width: "auto"}} 
          onClick={() => {setExamNumber({enrollmentId:enro.id, exam:enro.exam_id}); navigate("/Qustion")}}>Take the assessment</button>}
           {userData.managed_by == null && enro.finished == 1 ? <button style={{width: "auto", background: "#79B934", border: "0"}} 
           onClick={() => {window.open("/reportPage?enro_id=" + enro.id + "&exam_id=" + enro.exam_id, '_blank')}}  
           className="btn btn-primary">Show Report</button> : ""}</td>
      </tr>
        </>
      )
    })  : "loading"}
    </tbody>
  </table>
    </div>
      )
}

export default Enrollments;