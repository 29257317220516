import "./MainDashboard.css"
import LogoBlu from "../Logo/WebsiteLogoBlu.js"
import spacedashboard from "../../Media/Icons/Dashboard/spacedashboard.png"
import { useContext, useState } from "react"
import { useNavigate } from "react-router";
import axios from "axios";
import {appContext} from "../../App.js"
function AsideBar (props) {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const navigate = useNavigate();
    const isLoggedIn = useContext(appContext);

    function logout(e) {
        e.preventDefault();
        axios.delete("https://api.twindix.com/auth/logout", {headers:{
            Authorization:"Bearer " + localStorage.token
        }}).then(res => {
            console.log("logged out successfully");
            localStorage.removeItem("token");
            localStorage.removeItem("isAdmin");
            localStorage.removeItem("isLogged");
            localStorage.removeItem("userType");
            isLoggedIn.setUserToken(null);
            isLoggedIn.setLoggedin(null);
            isLoggedIn.setUserType(null);
            window.location.reload()
            navigate('/')
        }).catch(err => console.log(err))
    }


    function logoutad(e) {
        e.preventDefault();
        
        axios.delete("https://api.twindix.com/dashboard/auth/logout", {headers:{
            Authorization:"Bearer " + localStorage.token
        }}).then(res => {
            console.log("logged out successfully");
            localStorage.removeItem("token");
            localStorage.removeItem("isAdmin");
            localStorage.removeItem("isLogged");
            localStorage.removeItem("userType");
            isLoggedIn.setUserToken(null);
            isLoggedIn.setLoggedin(null);
            isLoggedIn.setUserType(null);
            isLoggedIn.setIsAdmin(null);
            navigate('/LoginForAdmin')
            window.location.reload()
        }).catch(err => console.log(err))

        // localStorage.removeItem('token');
        // isLoggedIn.setLoggedin(false)
        // navigate('/login', {replace:true});
    }

    return (
        <>
            <div className="AsideBar">
                <div className="LogoContaner">
                    <div className="DachpoardLogo">
                        <LogoBlu />
                    </div>
                </div>
                <div className="QuickAccess">
                    <div className="H2">
                        <h3>Quick Access</h3>
                    </div>
                    <div className="Sco">
                    <div className={activeIndex === 0 ? `AllDetils AllDetilsActive ${props.class1}`  : `AllDetils ${props.class1}`} onClick={() => handleClick(0)}>
                    <div className={activeIndex === 0 ? "Detils DetilsActive" : "Detils"} onClick={props.quickonclcikfun1}>
                        <span><img src={props.quickDetilssrc1} alt="icon" /></span>
                        <span>{props.quickDetilstext1}</span>
                    </div>
                </div>
                <div className={activeIndex === 1 ? `AllDetils AllDetilsActive ${props.class2}` : `AllDetils ${props.class2}`} onClick={() => handleClick(1)}>
                    <div className={activeIndex === 1 ? "Detils DetilsActive" : "Detils"} onClick={props.quickonclcikfun2}>
                        <span><img src={props.quickDetilssrc2} alt="icon" /></span>
                        <span>{props.quickDetilstext2}</span>
                    </div>
                </div>
                        <div className={activeIndex === 2 ? `AllDetils AllDetilsActive ${props.class3}` : `AllDetils ${props.class3}`} onClick={() => handleClick(2)}>
                            <div className={activeIndex === 2 ? "Detils DetilsActive" : "Detils"} onClick={props.quickonclcikfun3}>
                                <span><img src={props.quickDetilssrc3} alt="icon" /></span>
                                <span>{props.quickDetilstext3}</span>
                            </div>
                        </div>
                        <div className={activeIndex === 3 ? `AllDetils AllDetilsActive ${props.class4}` : `AllDetils ${props.class4}`} onClick={() => handleClick(3)}>
                            <div className={activeIndex === 3 ? "Detils DetilsActive" : "Detils"} onClick={props.quickonclcikfun4}>
                                <span><img src={props.quickDetilssrc4} alt="icon" /></span>
                                <span>{props.quickDetilstext4}</span>
                            </div>
                        </div>
                        <div className={activeIndex === 4 ? `AllDetils AllDetilsActive ${props.class5}` : `AllDetils ${props.class5}`} onClick={() => handleClick(4)}>
                            <div className={activeIndex === 4 ? "Detils DetilsActive" : "Detils"} onClick={props.quickonclcikfun5}>
                                <span><img src={props.quickDetilssrc5} alt="icon" /></span>
                                <span>{props.quickDetilstext5}</span>
                            </div>
                        </div>
                        <div className={activeIndex === 5 ? `AllDetils AllDetilsActive ${props.class6}` : `AllDetils ${props.class6}`} onClick={() => handleClick(5)}>
                            <div className={activeIndex === 5 ? "Detils DetilsActive" : "Detils"} onClick={props.quickonclcikfun6}>
                                <span><img src={props.quickDetilssrc6} alt="icon" /></span>
                                <span>{props.quickDetilstext6}</span>
                            </div>
                        </div>
                        <div className={activeIndex === 6 ? `AllDetils AllDetilsActive ${props.class7}` : `AllDetils ${props.class7}`} onClick={() => handleClick(6)}>
                            <div className={activeIndex === 6 ? "Detils DetilsActive" : "Detils"} onClick={props.quickonclcikfun7}>
                                <span><img src={props.quickDetilssrc7} alt="icon" /></span>
                                <span>{props.quickDetilstext7}</span>
                            </div>
                        </div>
                        <div className={activeIndex === 7 ? `AllDetils AllDetilsActive ${props.class8}` : `AllDetils ${props.class8}`} onClick={() => handleClick(7)}>
                            <div className={activeIndex === 7 ? "Detils DetilsActive" : "Detils"} onClick={props.quickonclcikfun8}>
                                <span><img src={props.quickDetilssrc8} alt="icon" /></span>
                                <span>{props.quickDetilstext8}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Account">
                    <div className="H2">
                        <h3>Account</h3>
                    </div>
                    <div className="Sco">
                        <div className={activeIndex === 8 ? "AllDetils AllDetilsActive" : "AllDetils"} onClick={() => handleClick(8)}>
                            <div className={activeIndex === 8 ? "Detils DetilsActive" : "Detils"} onClick={props.acconclcikfun9}>
                                <span><img src={props.accDetilssrc1} alt="icon" /></span>
                                <span>{props.accDetilstext1}</span>
                            </div>
                        </div>
                        <div className={activeIndex === 9 ? "AllDetils AllDetilsActive" : "AllDetils"} onClick={() => handleClick(9)}>
                            <div className={activeIndex === 9 ? "Detils DetilsActive" : "Detils"} onClick={props.acckonclcikfun10}>
                                <span><img src={props.accDetilssrc2} alt="icon" /></span>
                                <span>{props.accDetilstext2}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="LogOut">
                    <hr style={{margin: ".5rem 0"}} />
                    <div className="AllDetils">
                            <div className="Detils" onClick={isLoggedIn.userType === 1 || isLoggedIn.userType ===  2 ?logout :logoutad }>
                                <span><img src={props.logDetilssrc} alt="icon" /></span>
                                <span>{props.logDetilstext}</span>
                            </div>
                        </div>
                </div>
            </div>
        </>
    )
}

export default AsideBar