import ReportSec from "./ReportSec/ReportSec"
import "./ListCom.css"
function Report () {
    return (
        <>  
            <ReportSec />
        </>
    )
}

export default Report