import { redirect, useNavigate } from "react-router"
import LogInBoxForAdmin from "../../Componants/LoginForAdmin/LogInForAdminCom"
import Footer from "../../FemilerCom/Footer/Footer"
import Header from "../../FemilerCom/Header/Header"

function LogInForAdmin () {
    let navigate = useNavigate()
    window.onload = () => {
        if(localStorage.isAdmin && localStorage.token) {
            navigate("/admin")
        }
    }
    return (
        <>
            {/* <Header /> */}
            <LogInBoxForAdmin />
            {/* <Footer /> */}
        </>
    )
}

export default LogInForAdmin