import { useEffect, useState } from "react";
import axios from "axios";
const MyPacks = () => {
  const [users, setUsers] = useState();
  const [links, setLinks] = useState();
  const [loader, setLoader] = useState();
  const [alertMsg, setAlertMsg] = useState({code: false, msg: "", classes: ""});
  const [toggleType, setToggleType] = useState(true);
  // const [link, setLink] = useState("https://api.twindix.com/dashboard/users");
  useEffect(() => {
    if(!users) {
      axios.get("https://api.twindix.com/orders", {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
      setUsers(res.data.payload.data);

      console.log(res)
      setLinks(res.data.payload.links)
    })
}

}, [users, ])
function requestData(url) {
  setLoader(true)
  axios.get(url, {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
    setUsers(res.data.payload.data);
    setLinks(res.data.payload.links)
    let response = res.data.payload.data;
    setLoader(false)

  })
}
function sortType(e) {
  let myUsers;
  if(toggleType)  {
    myUsers = users.sort((a, b) => a.type - b.type);
    setUsers(myUsers);
      setToggleType(!toggleType)
  } else {
    myUsers = users.sort((a, b) => b.type - a.type);
    setUsers(myUsers);
      setToggleType(!toggleType)

  }
}

    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className={loader ? "loader" : "d-none"}><span className="spinner-border"></span></div>
        <div className={alertMsg.msg ? "d-block position-fixed top-0" : "d-none"}>
          <div className={alertMsg.classes}>{alertMsg.msg}  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  </div>
        </div>
{/* <nav aria-label="Page navigation example " style={{margin: "auto"}}>
  <ul className="pagination">
    {links ? links.map(link => {
      return (
        <li className="page-item"><a style={{cursor: "pointer"}} className="page-link" onClick={() =>requestData(link.url)} dangerouslySetInnerHTML={{__html: link.label}}/></li>
      )
    }) :""}

  </ul>
</nav> */}
        <table className="table">
  <thead>
    
    <tr>
      <th style={{cursor: "pointer", textAlign: "center", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px"}} className="tableheader" onClick={() => requestData("https://api.twindix.com/orders")} scope="col">Order id</th>
      <th style={{cursor: "pointer", textAlign: "center"}} className="tableheader" onClick={() => requestData("https://api.twindix.com/orders")} scope="col">Package Id</th>
      <th style={{cursor: "pointer", textAlign: "center", borderTopRightRadius: "5px", borderBottomRightRadius: "5px"}} className="tableheader" onClick={() => requestData("https://api.twindix.com/orders?sort=amount")} scope="col">Price</th>


    </tr>
  </thead>
  <tbody>
  {users ? users.map((user, index) => {
     return( <tr key={user.id}>
      <td className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"} scope="row" style={{textAlign: "center"}}>{user.id}</td>
      <td className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"} style={{textAlign: "center"}}>{user.orderable_id}</td>
      <td className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"} style={{textAlign: "center"}}>{user.amount}</td>
    </tr>)
  })  : "There is no packages"}
  </tbody>
</table>
  </div>
    )
}

export default MyPacks;