import chevron from "../../Media/Icons/Dashboard/chevron.png"
import Search from "../../Media/Icons/Dashboard/Search.png"
import person from "../../Media/Icons/Dashboard/account.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark} from '@fortawesome/free-solid-svg-icons'
function TopLine (props) {
    return (
        <>
            <div className="TopLine">
                <div className="LeftSection">
                    <span><img style={{width: "100%", height: "100%"}} src={Search} alt="icon"/></span>
                    <input />
                </div>
                
                    {props.closebar ? 
                        <span className="CloseBar"
                        onClick={props.toggleBarOnclick}><FontAwesomeIcon icon={faXmark} /></span>: 
                    <>
                        <span className="ToggleBar"
                        onClick={props.toggleBarOnclick}><FontAwesomeIcon icon={faBars} /></span>
                    </>
                    }
                <div className="RightSection">
                    <div className="TopLineContaner">
                        <div className="Image"><img style={{width: "90%", height: "90%"}} src={person} alt="icon"/></div>
                        <div className="Infor">
                            <div>welcome: {props.Name}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopLine