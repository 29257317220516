import { Link } from "react-router-dom"

function ResetPassAd () {
    return (
        <>
            <div className="Dashboardd">
            <Link to={"/Login/ResetPassword"} style={{width: "auto", margin: "auto", display: "inline-block", textDecoration: "none"}} ><button className="BtnCenterInd">Reset Password</button></Link>
            </div>
        </>
    )
}

export default ResetPassAd