import { Link } from "react-router-dom"
import "./ListCom.css"
function Dashboard () {
    return (
        <>
            <div className="Dashboardd">
            <Link to={"/Payment"} style={{width: "auto", margin: "auto", display: "inline-block"}} ><button className="BtnCenterInd">Buy Assessmen</button></Link>
            </div>
        </>
    )
}

export default Dashboard;