import "./Logo.css"
import LogoWiht from "../../Media/Logo/Rprt-Logo-B-w.png"
function LogoWhi () {
    return (
        <>
                <div className="Logo">
                    <img className="LogoImg" src={LogoWiht} alt="logo" />
                </div>
        </>
    )
}
export default LogoWhi