import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { appContext } from '../../../../App';

const ExamList = () => {
  const [enrollments, setEnrollments] = useState(null);
  const [loader, setLoader] = useState(false);
  const [alertMsg, setAlertMsg] = useState({ code: false, msg: "", classes: "" });
  const [currentDate, setCurrentDate] = useState('');
  
  const username = useContext(appContext);

  //get Current Date
  useEffect(() => {
      // Function to update the current date
      const updateDate = () => {
          const dateObj = new Date();
          const day = dateObj.getDate();
          const month = dateObj.getMonth() + 1; // Months are zero-based
          const year = dateObj.getFullYear();
          const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
          setCurrentDate(formattedDate);
      };

      // Initial call to update the date
      updateDate();
      
      // Set interval to update the date every day
      const intervalId = setInterval(updateDate, 86400000); // 24 hours in milliseconds
      
      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
  }, []);


  // get spreedsheet
  const handleExportToExcel = (answers) => {
    // Transform answers into an array of objects and convert "1.00" to 1 and "0.00" to 0
    const transformedData = Object.keys(answers).map(key => {
      let answer = parseFloat(answers[key]);
      if (!isNaN(answer) && answer % 1 === 0) {
        answer = parseInt(answer, 10); // Convert to integer if it's a whole number
      }
      return { Question_code: key, answer: answer };
    });
  
    // Convert answers to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Answers");
  
    // Generate Excel file and prompt download
    XLSX.writeFile(workbook, `twindix-${username.userName}-${currentDate}.xlsx`);
  };
  
  //fetch Enrollments
  const fetchAllEnrollments = async () => {
    setLoader(true);
    let allEnrollments = [];
    let currentPage = 1;
    let lastPage = 1;

    try {
        do {
            const response = await axios.get(`https://api.twindix.com/dashboard/enrollments?page=${currentPage}`, {
                headers: { Authorization: "Bearer " + localStorage.token }
            });
            console.log('Response:',response.data.payload.data); // Log the entire response
            const data = response.data.payload.data;
            const meta = response.data.payload;
            allEnrollments = [...allEnrollments, ...data];
            currentPage = meta.current_page + 1;
            lastPage = meta.last_page;
        } while (currentPage <= lastPage);
    } catch (error) {
        console.error("Error fetching enrollments:", error);
    }

    setEnrollments(allEnrollments);
    setLoader(false);
};
useEffect(() => {
  fetchAllEnrollments();
}, []);

  //ferch users
  const [users, setUsers] = useState();
  useEffect(() => {
    if(!users) {
      axios.get("https://api.twindix.com/dashboard/users", {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
      setUsers(res.data.payload.data);
      console.log(res.data.payload.data)
    })
    }

}, [users, ])

  /// fetch Exams
  let [examList, setexamList ]= useState()
  const fetchExams = (url) => {
    setLoader(true);
    axios
      .get(url, {
        headers: { Authorization: "Bearer " + localStorage.token },
      })
      .then((res) => {
        setexamList(res.data.payload.data);
        console.log(res.data.payload)
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching exams:", error);
        setLoader(false);
      });
  };
  useEffect(() => {
    if (!examList) {
      fetchExams("https://api.twindix.com/dashboard/exams");
    }
    console.log(examList);
  }, [examList]);
  return (
    <div>
      <h1>Exam spreadsheet</h1>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className={loader ? "loader" : "d-none"}><span className="spinner-border"></span></div>
        <div className={alertMsg.msg ? "d-block position-fixed top-0" : "d-none"}>
          <div className={alertMsg.classes}>{alertMsg.msg}  
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th style={{ cursor: "pointer", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }} className="tableheader" scope="col">id</th>
              <th style={{ cursor: "pointer" }} className="tableheader" scope="col">Code</th>
              <th style={{ cursor: "pointer" }} className="tableheader" scope="col">User Name</th>
              <th style={{ cursor: "pointer" }} className="tableheader" scope="col">Finished</th>
              <th style={{ cursor: "pointer" }} className="tableheader" scope="col">Order Id</th>
              <th style={{ cursor: "pointer" }} className="tableheader" scope="col">Exam Title</th>
              <th style={{ cursor: "pointer", borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }} className="tableheader" scope="col">Action</th>
            </tr> 
          </thead>
          <tbody>
            {enrollments ? (
              enrollments.map((exam, index) => {
                let  userName, examtitle ;
                users.map((user) => user.id == exam.user_id ? userName = user.name : "")
                examList.map((spasficexam) => spasficexam.id == exam.exam_id ? examtitle = spasficexam.title : "")
                if (exam.finished == 1) {
                  return (
                    <tr key={exam.id}>
                      <th className={index % 2 === 0 ? "tablerow tablerowColor" : "tablerow"} scope="row">
                        {exam.user_id}
                      </th>
                      <td className={index % 2 === 0 ? "tablerow tablerowColor" : "tablerow"}>
                        {exam.code}
                      </td>
                      <td className={index % 2 === 0 ? "tablerow tablerowColor" : "tablerow"}>
                        {userName}
                      </td>
                      <td className={index % 2 === 0 ? "tablerow tablerowColor" : "tablerow"}>
                        <span className="text-success">Finished</span>
                      </td>
                      <td className={index % 2 === 0 ? "tablerow tablerowColor" : "tablerow"}>
                        {exam.order_id}
                      </td>
                      <td className={index % 2 === 0 ? "tablerow tablerowColor" : "tablerow"}>
                        {examtitle}
                      </td>
                      <td className={index % 2 === 0 ? "tablerow tablerowColor Flex" : "tablerow Flex"}>
                        <button
                          style={{ margin: "auto" }}
                          className="btn btn-primary"
                          onClick={() => handleExportToExcel(exam.answers)}>
                          Download Exil
                        </button>
                      </td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })
            ) : (
              <tr>
                <td colSpan="6">There are no enrollments</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
    // <></>
  );
};

export default ExamList;
