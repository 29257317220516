import axios from "axios";
import { useContext } from "react";
import { appContext } from "../../../../App";

function GetProfileIfro () {
    const isLoggedIn = useContext(appContext);
    console.log(isLoggedIn)
    return (
        <>
            <div className="GetProfileIfro">
            <div class="container">
    <div class="profile">
      <div class="profile-picture">
        <img src={isLoggedIn.company_logo} alt="" />
      </div>
      <div class="profile-details">
        <h1>{isLoggedIn.userName}</h1>
        <p>Email: {isLoggedIn.userEmail}</p>
        <p>Phone: {isLoggedIn.userData.phone}</p>
        <p>Company: {isLoggedIn.userData.company_name}</p>
        <p>Job Title: {isLoggedIn.userData.job_title}</p>
      </div>
    </div>
  </div>
            </div>
        </>
    )
}

export default GetProfileIfro