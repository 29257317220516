import { Link, useNavigate } from "react-router-dom"
import "./ReportSec.css"
// import Button from "../../../../../../FemilerCom/Button/Button"

import { useContext, useEffect, useState } from "react"
import axios from "axios"
import { appContext } from "../../../../../App";
function ReportSec () {
    let [enrollments, setEnrollments] = new useState();
    const [links, setLinks] = useState();
    const [loader, setLoader] = useState();
    const [alertMsg, setAlertMsg] = useState({code: false, msg: "", classes: ""});
    const [toggleType, setToggleType] = useState(true);
    const {setExamNumber, examNumber, userData} = useContext(appContext);
    let navigate = new useNavigate();
    function requestData(url) {
        setLoader(true)
        axios.get(url, {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
          setEnrollments(res.data.payload.data);
          setLinks(res.data.payload.links)
          let response = res.data.payload.data;
          setLoader(false)
        })
      }
      function sortType(e) {
        let myenrollments;
        if(toggleType)  {
          myenrollments = enrollments.sort((a, b) => a.type - b.type);
          setEnrollments(myenrollments);
            setToggleType(!toggleType)
        } else {
          myenrollments = enrollments.sort((a, b) => b.type - a.type);
          setEnrollments(myenrollments);
            setToggleType(!toggleType)
      
        }
      }
      function dropUser(id) {
        axios.delete("https://api.twindix.com/enrollments/" + id, {headers: {Authorization: "Bearer " + localStorage.token}}).then((res) => {
          console.log(res)
          if(res.status) {
            setAlertMsg({deleted: true,msg: res.data.message, classes: "alert alert-success alert-dismissible fade show"})
          } else {
            setAlertMsg({deleted: false,msg: "proplem occured", classes:"alert alert-danger alert-dismissible fade show"})
          }
          axios.get("https://api.twindix.com/enrollments/", {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
            setEnrollments(res.data.payload.data);
            setLinks(res.data.payload.links)
          })
        })
        
      }
      useEffect(() => {
        if(!enrollments) {
          axios.get("https://api.twindix.com/enrollments", {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
          setEnrollments(res.data.payload.data);
    
          setLinks(res.data.payload.links)
        })
        console.log(enrollments)

        }
        console.log(enrollments)
    
    }, [enrollments, ])
    return (
        <>
                  <div className="d-flex flex-column justify-content-center align-items-center">
        <div className={loader ? "loader" : "d-none"}><span className="spinner-border"></span></div>
        <div className={alertMsg.msg ? "d-block position-fixed top-0" : "d-none"}>
          <div className={alertMsg.classes}>{alertMsg.msg}  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  </div>
        </div>

        <table className="table">
  <thead>
    
    <tr>
      <th style={{cursor: "pointer", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px"}} className="tableheader" onClick={() => requestData("https://api.twindix.com/dashboard/enrollments")} scope="col">id</th>
      <th style={{cursor: "pointer"}} className="tableheader" onClick={() => requestData("https://api.twindix.com/dashboard/enrollments?sort=name")} scope="col">Code</th>
      <th style={{cursor: "pointer"}} className="tableheader" onClick={() => requestData("https://api.twindix.com/dashboard/enrollments?sort=email")} scope="col">Finished</th>
      <th style={{cursor: "pointer"}} className="tableheader"  scope="col">Order Id</th>
      <th style={{cursor: "pointer"}} className="tableheader" onClick={sortType} scope="col">Type</th>
      <th style={{cursor: "pointer", borderTopRightRadius: "5px", borderBottomRightRadius: "5px"}} className="tableheader" onClick={sortType} scope="col">Action</th>
      {/* <th style={{cursor: "pointer"}} className="table-header"  scope="col">Action</th> */}
    </tr>
  </thead>
  <tbody>
  {enrollments ? enrollments.map((user, index) => {
    return( 
      <>
        <tr key={user.id}>
          <th className={index % 2 === 0 ? "tablerow tablerowColor" : "tablerow"}   scope="row">{user.id}</th>
          <td className={index % 2 === 0 ? "tablerow tablerowColor" : "tablerow"}  >{user.code}</td>
          <td className={index % 2 === 0 ? "tablerow tablerowColor" : "tablerow"}  >{user.finished == 1 ? <span className="text-success">Finished</span> : <span className="text-danger">Not Finished Yet</span>}</td>
          <td className={index % 2 === 0 ? "tablerow tablerowColor" : "tablerow"}  >{user.order_id}</td>
          <td className={index % 2 === 0 ? "tablerow tablerowColor" : "tablerow"}  >{user.exam_id}</td>
          <td className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"} style={{textAlign: "center"}}>
          {user.finished == 1 ? "" : <button className="btn btn-primary" style={{width: "auto"}} 
          onClick={() => {setExamNumber({enrollmentId:user.id, exam:user.exam_id}); navigate("/Qustion")}}>Take the assessment</button>}
           {userData.managed_by == null && user.finished == 1 ? <button style={{width: "auto", background: "#79B934", border: "0"}} 
           onClick={() => {window.open("/reportPage?enro_id=" + user.id + "&exam_id=" + user.exam_id, '_blank')}}  
           className="btn btn-primary">Show Report</button> : ""}</td>        </tr>
      </>
    )
  })  : "There is no enrollments"}
  </tbody>
</table>
  </div>
        </>
    )
}

export default ReportSec