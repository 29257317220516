import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./ListCom.css";
import Button from "../../../../FemilerCom/Button/Button";
import axios from "axios";

function EditOldTest() {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
    template_id: "",
    questions: null,
    question_algorithms: null,
    combined_algorithms: null,
    cover_image: null,
  });

  const [fileNames, setFileNames] = useState({
    questionsname: "",
    question_algorithms: "",
    combined_algorithms: "",
    cover_image: "",
  });

  const [exams, setExams] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    const isFileInput = type === "file";

    if (isFileInput) {
      setFileNames((prevFileNames) => ({
        ...prevFileNames,
        [name]: files[0]?.name || "",
      }));
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      const isModified = value !== exams?.[name];
      setFormData((prevData) => ({
        ...prevData,
        [name]: isModified ? value : exams?.[name],
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("price", formData.price);
    formDataToSend.append("template_id", formData.template_id);

    if (formData.questions) {
      formDataToSend.append("questions", formData.questions);
    }
    if (formData.question_algorithms) {
      formDataToSend.append("question_algorithms", formData.question_algorithms);
    }
    if (formData.combined_algorithms) {
      formDataToSend.append("combined_algorithms", formData.combined_algorithms);
    }
    if (formData.cover_image) {
      formDataToSend.append("cover_image", formData.cover_image);
    }

    // Log formDataToSend to the console
    for (let pair of formDataToSend.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }

    axios
      .post(
        `https://api.twindix.com/dashboard/exams/${sessionStorage.editId}?_method=put`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
            "Content-Type": "multipart/form-data",
          },
          maxBodyLength: Infinity,
        }
      )
      .then((res) => {
        sessionStorage.removeItem("editId");
        setLoading(false);
        navigate("/admin");
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);

        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        } else {
          console.error('Error message:', error.message);
        }
      });
  };

  useEffect(() => {
    if (!exams && sessionStorage.editId) {
      axios
        .get(`https://api.twindix.com/dashboard/exams/${sessionStorage.editId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          setExams(res.data.payload);
          setFormData({
            title: res.data.payload.title || "",
            description: res.data.payload.description || "",
            price: res.data.payload.price || "",
            template_id: res.data.payload.template_id || "",
          });
          setFileNames({
            questionsname: res.data.payload.questionsname || "",
            question_algorithms: res.data.payload.question_algorithms || "",
            combined_algorithms: res.data.payload.combined_algorithms || "",
            cover_image: res.data.payload.cover_image || "",
          });
        })
        .catch((error) => {
          console.error("Error fetching exam data:", error);
        });
    }
  }, [exams]);

  return (
    <>
      <div className={loading ? "LoadingOverlay" : "display"}>
        <span className="LoadingSpinner"></span>
      </div>
      <div className="EditTest">
        <div className="EdittTestCon">
          <h3>Edit Test</h3>
          <form onSubmit={handleSubmit}>
            <div style={{display: "flex"}}>
            <div className="InpuCard">
              <span>Title Test</span>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                />
            </div>
            <div  className="InpuCard">
              <span>Price</span>
              <input
                type="text"
                name="price"
                value={formData.price}
                onChange={handleChange}
                />
            </div>
            </div>
            <div style={{display : "flex"}}>
                <div  className="InpuCard">
                  <span>Description</span>
                  <textarea
                    className="TextArea"
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                />
                </div>
                <div className="InpuCard">
                  <span>Template Id</span>
                  <input
                    type="number"
                    name="template_id"
                    value={formData.template_id}
                    onChange={handleChange}
                  />
                </div>
            </div>
            <div className="Flex DragAndDrob">
              <div className="DragFiles">
                Questions
                <input
                  onChange={handleChange}
                  type="file"
                  name="questions"
                  style={{ width: "100%" }}
                />
                {fileNames.questions ? (
                  <span>{fileNames.questions}</span>
                ) : (
                  <span className="DrahMSG">Drag and drop or click to upload</span>
                )}
              </div>
              <div className="DragFiles">
                Question Algorithm
                <input
                  onChange={handleChange}
                  type="file"
                  name="question_algorithms"
                  style={{ width: "100%" }}
                />
                {fileNames.question_algorithms ? (
                  <span>{fileNames.question_algorithms}</span>
                ) : (
                  <span className="DrahMSG">Drag and drop or click to upload</span>
                )}
              </div>
              <div className="DragFiles">
                Combined Algorithms
                <input
                  onChange={handleChange}
                  type="file"
                  name="combined_algorithms"
                  style={{ width: "100%" }}
                />
                {fileNames.combined_algorithms ? (
                  <span>{fileNames.combined_algorithms}</span>
                ) : (
                  <span className="DrahMSG">Drag and drop or click to upload</span>
                )}
              </div>
              <div className="DragFiles">
                Cover Image
                <input
                  onChange={handleChange}
                  type="file"
                  name="cover_image"
                  style={{ width: "100%" }}
                />
                {fileNames.cover_image &&
                  <span className="DrahMSG">Drag and drop or click to upload</span>}
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div className="BtnAddTest">
                <Button Text="Save" />
              </div>
              <Link to={"/Admin"}>
                <div className="BtnAddTest">
                  <Button Text="Go To Dashboard" />
                </div>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditOldTest;


// import { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import "./ListCom.css";
// import Button from "../../../../FemilerCom/Button/Button";
// import axios from "axios";

// function EditOldTest() {
//   const [formData, setFormData] = useState({
//     title: "",
//     description: "",
//     price: "",
//     template_id: "",
//     questions: null,
//     question_algorithms: null,
//     combined_algorithms: null,
//     cover_image: null,
//   });

//   const [fileNames, setFileNames] = useState({
//     questions: "",
//     question_algorithms: "",
//     combined_algorithms: "",
//     cover_image: "",
//   });

//   const [exams, setExams] = useState(null);
//   const [loading, setLoading] = useState(false);

//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value, type, files } = e.target;
//     const isFileInput = type === "file";

//     if (isFileInput) {
//       setFileNames((prevFileNames) => ({
//         ...prevFileNames,
//         [name]: files[0]?.name || "",
//       }));
//       setFormData((prevData) => ({
//         ...prevData,
//         [name]: files[0],
//       }));
//     } else {
//       const isModified = value !== exams?.[name];
//       setFormData((prevData) => ({
//         ...prevData,
//         [name]: isModified ? value : exams?.[name],
//       }));
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setLoading(true);

//     const formDataToSend = new FormData();
//     formDataToSend.append("title", formData.title);
//     formDataToSend.append("description", formData.description);
//     formDataToSend.append("price", formData.price);
//     formDataToSend.append("template_id", formData.template_id);

//     if (formData.questions) {
//       formDataToSend.append("questions", formData.questions);
//     }
//     if (formData.question_algorithms) {
//       formDataToSend.append("question_algorithms", formData.question_algorithms);
//     }
//     if (formData.combined_algorithms) {
//       formDataToSend.append("combined_algorithms", formData.combined_algorithms);
//     }
//     if (formData.cover_image) {
//       formDataToSend.append("cover_image", formData.cover_image);
//     }

//     // Log formDataToSend to the console
//     for (let pair of formDataToSend.entries()) {
//       console.log(pair[0] + ': ' + pair[1]);
//     }

//     axios
//       .post(
//         `https://api.twindix.com/dashboard/exams/${sessionStorage.editId}?_method=put`,
//         formDataToSend,
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.token}`,
//             "Content-Type": "multipart/form-data",
//           },
//           maxBodyLength: Infinity,
//         }
//       )
//       .then((res) => {
//         console.log(res);
//         sessionStorage.removeItem("editId");
//         setLoading(false);
//         navigate("/admin");
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//         setLoading(false);

//         if (error.response) {
//           console.error('Response data:', error.response.data);
//           console.error('Response status:', error.response.status);
//           console.error('Response headers:', error.response.headers);
//         } else {
//           console.error('Error message:', error.message);
//         }
//       });
//   };

//   useEffect(() => {
//     if (!exams && sessionStorage.editId) {
//       axios
//         .get(`https://api.twindix.com/dashboard/exams/${sessionStorage.editId}`, {
//           headers: {
//             Authorization: `Bearer ${localStorage.token}`,
//           },
//         })
//         .then((res) => {
//           setExams(res.data.payload);
//           setFormData({
//             title: res.data.payload.title || "",
//             description: res.data.payload.description || "",
//             price: res.data.payload.price || "",
//             template_id: res.data.payload.template_id || "",
//           });
//           setFileNames({
//             questions: res.data.payload.questions || "",
//             question_algorithms: res.data.payload.question_algorithms || "",
//             combined_algorithms: res.data.payload.combined_algorithms || "",
//             cover_image: res.data.payload.cover_image || "",
//           });
//         })
//         .catch((error) => {
//           console.error("Error fetching exam data:", error);
//         });
//     }
//   }, [exams]);

//   return (
//     <>
//       <div className={loading ? "LoadingOverlay" : "display"}>
//         <span className="LoadingSpinner"></span>
//       </div>
//       <div id="DoneMess" className="ExamDoneAlert"></div>
//       <div className="AddTest">
//         <h3>Edit Test</h3>
//         <form onSubmit={handleSubmit}>
//           <h2>Title Test</h2>
//           <input
//             type="text"
//             name="title"
//             value={formData.title}
//             onChange={handleChange}
//           />
//           <h2>Description</h2>
//           <textarea
//             className="TextArea"
//             type="text"
//             name="description"
//             value={formData.description}
//             onChange={handleChange}
//           />
//           <h2>Price</h2>
//           <input
//             type="text"
//             name="price"
//             value={formData.price}
//             onChange={handleChange}
//           />
//           <br />
//           <h2>Template Id</h2>
//           <input
//             type="number"
//             name="template_id"
//             value={formData.template_id}
//             onChange={handleChange}
//           />
//           <br />
//           <div className="Flex">
//             <div className="DragFiles d-flex flex-column">
//               Questions
//               <input
//                 onChange={handleChange}
//                 type="file"
//                 name="questions"
//               />
//               {/* {fileNames.questions && <span>{fileNames.questions}</span>} */}
//             </div>
//             <div className="DragFiles d-flex flex-column">
//               Question Algorithm
//               <input
//                 onChange={handleChange}
//                 type="file"
//                 name="question_algorithms"
//               />
//               {/* {fileNames.question_algorithms && <span>{fileNames.question_algorithms}</span>} */}
//             </div>
//             <div className="DragFiles d-flex flex-column">
//               Combined Algorithms
//               <input
//                 onChange={handleChange}
//                 type="file"
//                 name="combined_algorithms"
//               />
//               {/* {fileNames.combined_algorithms && <span>{fileNames.combined_algorithms}</span>} */}
//             </div>
//             <div className="DragFiles d-flex flex-column">
//               Cover Image
//               <input
//                 onChange={handleChange}
//                 type="file"
//                 name="cover_image"
//               />
//               {/* {fileNames.cover_image && <span>{fileNames.cover_image}</span>} */}
//             </div>
//           </div>
//           <br />
//           <div className="BtnAddTest" style={{width: "145px", display: "inline-block", position: "absolute", bottom: "20px", left: "120px"}}>
//             <Button Text="Save" />
//           </div>
//           <Link to={"/Admin"} style={{width: "145px", display: "inline-block", position: "absolute", bottom: "20px", right: "120px"}}>
//             <div className="BtnAddTest">
//               <Button Text="Go To Dashboard" />
//             </div>
//           </Link>
//         </form>
//       </div>
//     </>
//   );
// }

// export default EditOldTest;
