import { dom } from "@fortawesome/fontawesome-svg-core"
import RegisterBox from "../../Componants/Register/RegisterCom"
import Button from "../../FemilerCom/Button/Button"
import Footer from "../../FemilerCom/Footer/Footer"
import Header from "../../FemilerCom/Header/Header"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
function Register () {
    let navigate = useNavigate()
    useEffect(() => {
        if(localStorage.token) {
            if(localStorage.userType == 2) {
                navigate("/individual", {replace: true})
                console.log("navigating")
            } else if(localStorage.userType == 1) {
                navigate("/hr", {replace: true})

            }
        }
    }, [])
    
    return (
        <>
            {/* <Header /> */}
            <div > 
                <RegisterBox />
            </div>
            {/* <Footer /> */}
        </>
    )
} 

export default Register