import { createContext, useEffect, useRef, useState } from 'react';
import './App.css';
import CodeField from './Componants/CodeField/CodeField';
import Dashboard from './Pages/Dache/Dashboard/Dashboard.js';
import LogIn from './Pages/LogIn/LogIn';
import Payment from './Pages/Payment/Payment';
import Qustion from './Pages/Qustion/Qustion';
import Register from './Pages/Register/Register';
import ReportPage from './Pages/Report/Report';
import {HashRouter as Router,Route,Routes, Link, useNavigate, Navigate} from 'react-router-dom';
import ResetPassword from './Componants/ResetPassword/ResetPass';
import ResetPasswordForAdmin from './Componants/ResetPasswordForAdmin/ResetPassForAdmin';
import LogInForAdmin from './Pages/LogInForAdmin/LogInForAdmin';
// import EditReportPage from './Componants/Dashboard/AdminDashboard/ListComponand/EditReportPage/EditReportPage';
import HrPayment from "./Componants/hrPayment/HrPayment"
// import EditQustion from './Componants/Dashboard/AdminDashboard/ListComponand/EditQustion/EditQustion';
// import AddQustion from './Componants/Dashboard/AdminDashboard/ListComponand/AddQustion/AddQustion';
import axios from 'axios';
import AddEmployee from './Componants/Dsche/HR/List/AddEmployee.js';
import EditOldTest from './Componants/Dsche/Admin/List/EditOldTest.js';
import AddTemp from './Componants/Dsche/Admin/List/AddTemp.js';
import EditTemp from './Componants/Dsche/Admin/List/EditTemp.js';
import Invite from './Componants/Dsche/HR/Invite.js';
import AddAdmin from './Componants/Dashboard/AdminDashboard/AddAdmin';
import AddPacks from './Componants/Dsche/Admin/List/AddPacks.js';
// export let TestContext = createContext(null);
export let appContext = createContext(null);
// start Function From Qustion To Report
function getInitialStateFromQustion() {
  let loaclFromQus = window.localStorage.getItem('test');
  if (loaclFromQus !== 'undefined') {
    return loaclFromQus ? JSON.parse(loaclFromQus) : ""
  }
}
// End Function From Qustion To Report

// Start Function From Qustion To Report
function getInitialStateFromAdmin() {
  
  const localFromAdmin = localStorage.getItem('box')
  if (localFromAdmin !== 'undefined'){
    return localFromAdmin ? JSON.parse(localFromAdmin) : ""
  }
}

// End Function From Qustion To Report
function App() {

  // Start  From Qustion To Report

    let [test, settest] = useState(getInitialStateFromQustion);
    let [isLoggedin, setLoggedin] = useState(true);
    let [isAdmin, setIsAdmin] = useState(false);
    let [userType, setUserType] = useState(null);
    let [userToken, setUserToken] = useState(null);
    let [userName, setUserName] = useState(null);
    let [userEmail, setUserEmail] = useState(null);
    let [userData, setUserData] = useState(null);
    let [examNumber, setExamNumber] = useState(null);
    let [homePage, setHomePage] = useState(null);
    // let testFun = () => {
    //   test ? settest(false): settest(true);
    //   console.log("done");
    // }

      useEffect(() => {
        if(localStorage.token && !localStorage.isAdmin) {

        setLoggedin(true);
        setUserToken(localStorage.token);
            axios.get("https://api.twindix.com/auth/me", {headers: {
              Authorization: 'Bearer ' + localStorage.token
          }
      }).then(res => {
              if(res.data.code == 200) {
                  setUserType(res.data.payload.type);
                  setUserName(res.data.payload.name);
                  setUserEmail(res.data.payload.email);
                  setUserData(res.data.payload)
                localStorage.setItem("userType", res.data.payload.type);
                  // console.log(res)
                  if(localStorage.userType == 2) {
                    setHomePage("/individual")
                  } else if(localStorage.userType == 1) {
                    setHomePage("/hr")
                  } else if(localStorage.userType == 3) {
                    setHomePage("/admin")
                  }
                  }
                  }).catch((res) => {
                    
                    console.log(res)
                    if(res.response.data.code == 401) {
                      localStorage.removeItem("isAdmin")
                      localStorage.removeItem("token")
                      localStorage.removeItem("userType")
                      localStorage.removeItem("isLogged")
            }
          });

        } else if(localStorage.token && localStorage.isAdmin) {
          axios.get("https://api.twindix.com/dashboard/auth/me", {headers: {
            Authorization: 'Bearer ' + localStorage.token
        }

    }).then(res => {
            if(res.data.code == 200) {
              console.log(res)
              setHomePage("/admin")
                setUserName(res.data.payload.name);
                setUserEmail(res.data.payload.email);
                setUserType(null)
                localStorage.setItem("isAdmin", true);
                localStorage.isLogged = true;
            }
        }).catch(() => {console.log("invalid token")});
        } else {
          setLoggedin(false);
          setUserToken(null);
          localStorage.removeItem("isLogged");
          localStorage.removeItem( "token" );
          localStorage.removeItem( "userType" );
          localStorage.removeItem( "isLoggedIn" );
        }
      }, [isLoggedin])
      useEffect(() => {
        localStorage.setItem('test', JSON.stringify(test))
      }, [test])
    // End From Qustion To Report

    // // start From Admin Dashbord To Report
    // let [box, setbox] = useState(getInitialStateFromAdmin);
    // let fromAdmicFun = () => {
    //   box ? setbox(false): setbox(true);
    //   console.log("done");
    // }

    // let [tiltemessage, settilteMessage] = useState('');
    // let [StitcCon, setStitcCon] = useState('');
    // let [Preef, setPreef] = useState('');
    
    // let titleRef = useRef("")
    // let StitcConRef = useRef("")
    // let PreefRef = useRef("")

    // const hundlerState = (event) => {
    //     let titlee = titleRef.current.value;
    //     let stitc = StitcConRef.current.value;
    //     let Pref = PreefRef.current.value;
    //     event.preventDefault();
    //     event.target.reset();
    //     settilteMessage(`The Title Is ${titlee}`);
    //     setStitcCon(`The stitc Is ${stitc}`);
    //     setPreef(`The Pref Is ${Pref}`);
    // };

    // useEffect(() => {
    //   localStorage.setItem('box', JSON.stringify(box))
    // }, [box])

    // // End From Admin Dashbord To Report

  // let usersValues = {test, settest, testFun, hundlerState , titleRef, StitcConRef, PreefRef, box, setbox, tiltemessage, StitcCon, Preef, fromAdmicFun}
  return (
        // <TestContext.Provider value={usersValues}>
          <appContext.Provider value={{isLoggedin,examNumber,localStorage,  setExamNumber, setLoggedin, setUserToken, setUserType,userData, userName,setUserName,userEmail, userType, isAdmin, setIsAdmin}}>
    <div className="App">
    <Routes>
        <Route path='/Register' element={!localStorage.token && !localStorage.isLogged ? <Register /> : <Navigate to={homePage} /> } /> 
        <Route path='/' element={!localStorage.token && !localStorage.isLogged ? <LogIn /> : <Navigate to={homePage} /> } />
        <Route path='/Payment' element={isLoggedin && userType == 2 ? <Payment /> : <Navigate to="/" replace={true} />} />
        <Route path='/HRPayment' element={isLoggedin && userType == 1 ? <HrPayment /> : <Navigate to="/" replace={true} />} />
        <Route path='/AddTemp' element={<AddTemp />} />
        <Route path='/editTemplate' element={<EditTemp />} />
        <Route path='/addadmin' element={<AddAdmin />} />
        <Route path='/editexam' element={localStorage.token && localStorage.isAdmin ?  <EditOldTest /> : <LogIn />} />
        <Route path='/addEmployee' element={<AddEmployee />} />
        <Route path='/LogInForAdmin' element={<LogInForAdmin /> } />
        <Route path='/Qustion' element={1 ? <Qustion/> : <Navigate to="/individual" replace={true} /> } />
        <Route path='/HR' element={localStorage.token && localStorage.userType == 1 ? <Dashboard/> : <Navigate to="/" replace={true} /> } />
        <Route path='/Individual' element={localStorage.token && localStorage.userType == 2 ? <Dashboard/> : <Navigate to="/" replace={true} /> } />
        <Route path='/Admin' element={<Dashboard /> } />
        <Route path='/verify-payment' element={localStorage.token && localStorage.userType ? <CodeField /> : <Navigate to="/" replace={true} />} />
        <Route className="PDF" id='PDF' path='/ReportPage' element={<ReportPage /> } />
        {/* <Route path='/EditReportPage' element={<EditReportPage  /> } />  */}
        {/* <Route path='/EditQustion' element={<EditQustion  /> } />  */}
        {/* <Route path='/AddQustion' element={<AddQustion  /> } />  */}
        <Route path='/Login/ResetPassword' element={<ResetPassword  /> } /> 
        <Route path='/LogInForAdmin/ResetPasswordForAdmin' element={<ResetPasswordForAdmin  /> } />
        <Route path='/invitee' element={<Invite />} />
        <Route path='/AddPacks' element={<AddPacks />} />
    </Routes>
    </div>
    </appContext.Provider>
    // </TestContext.Provider>
  );
}

export default App;


