import React, { useState } from 'react';
import validator from 'validator';
import "./Reset.css"
import LogoWhi from "../../Media/Logo/WebsiteLogoWiht.png";
import emailimg from "../../Media/Icons/Forms/icons8-email-26.png"
import Button from '../../FemilerCom/Button/Button';
function ResetPassword ()  {
  const [email, setEmail] = useState('');
  const [resetSent, setResetSent] = useState(false);
  const [error, setError] = useState();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleResetSubmit = async (event) => {
    event.preventDefault();
if(validator.isEmail(email)) {
  try {
    const response = await fetch('https://api.twindix.com/auth/forgot-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    const data = await response.json();
    console.log(data)
    if (response.ok) {
      setResetSent(true);
    } else {
      // Handle error, show message to the user
      console.error('Error:', data.error);
    }
  } catch (error) {
    console.error('Error:', error);
  }
} else {
  setError("make sure the email is valid");
}
    // Call your backend API to initiate the password reset process
    
  };

  return (
    <>
      <div className='ResetPasswrd'>
        <div className='ResetPssContaner'>
          <div className="ResetPassLogo">
              <div ><img src={LogoWhi} alt="user" /></div>
          </div>
            {error ? <div style={{left: "50%",
            transform: "translate(-50%)"}} className='alert alert-danger position-absolute top-0 text-center '>
              {error}
            </div> : ""}
            <div className="ResePasstBox">
              <div className="ResetPassText">
                  <h1 className="H1tex">Reset Password</h1>
              </div>
              {resetSent ? (
                <div className='alert alert-success'>Password reset instructions sent to your email.</div>
              ) :
              (
              <form className="SupmitForm" action="" onSubmit={handleResetSubmit}>
                <div className="InptCont">
                  <div className="ImgAndTilte">
                    <img className="ImgeInput" src={emailimg} alt="ss" />
                    <div className="title">Email</div>
                    <input
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      required
                      className='RegInput'
                    />                        
                  </div>
                </div>
                  <div className="ResetPassBtns">
                  <div className="ToSend" type="submit">
                            <Button Text="Send"  />
                        </div>
                  </div>
              </form>
              )}
          </div> 
        </div>
      </div>
    </>

  );
};

export default ResetPassword;
