import { Link } from "react-router-dom";
import Button from "../../FemilerCom/Button/Button";
import "./CodeField.css"
import CodeVildation from "./CodeVildation";
import { useEffect, useState } from "react";
import LogoWhi from "../../Media/Logo/WebsiteLogoWiht.png";
import user from "../../Media/Icons/Forms/icons8-user-96.png"
const IndividualCodeField = (props) => {
    let [error, seterror] = useState([])
    let [vlidation, setvlidation] = useState ({
        email: ' ',
        password: ' ',

    });
    let vlidationinput = (e) => {
        setvlidation(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    let VildationSupmitFun = (e) => {
        e.preventDefault();
        seterror(CodeVildation(vlidation))
    }

    return (
        <div className="CodeField">
            <div className="CodeFieldContaner">
            <div className="CodeFieldLogo">
                    <div ><img src={LogoWhi} alt="user" /></div>
                </div>
                <div className="CodeFieldBox">
                <div className="CodeFieldText">
                        <div style={{backgroundColor: "#24509a", padding:"6px", borderRadius: "50%", marginRight: "4px"}}>
                        <img src={user} alt="ss" />
                        </div>
                        <h1 className="H1tex">Individual Code</h1>
                    </div>
                    <div className="text-center">
                    <div style={{color: "white", fontSize: "24px"}}>Exam Title: {props.examName}</div>
                    <div style={{color: "white", fontSize: "24px"}}>Exam Price: <span className="text-success">{props.examPrice}</span></div>
                    <div style={{color: "white", fontSize: "24px"}}>Exam Code: <span className="text-danger">{props.enrollmentCode}</span></div>
                </div>
                <form className="SupmitForm" onSubmit={VildationSupmitFun}>
                    <div className="InptCont">
                        <div className="ImgAndTilte">
                            <div className="title">Code</div>
                            <input onChange={vlidationinput} className="RegInput"  name="username"    />
                        </div>
                        <div  className="ErrorText">{error.username && <span>{error.username} </span>}</div>
                    </div>
                <div className="CodeFieldBtns">
                    <Link to={"/Individual"} style={{margin:"auto"}}>
                        <div id="HR" className="Contaner ToCode ">
                                <Button className="CodeFieldBTN" Text="Go To Dashboard"  />
                        </div>
                    </Link>
                </div>
                </form>
                </div>
            </div>
    </div>
    )
}

export default IndividualCodeField;