import axios from "axios";
import { useState } from "react";
import LogoBlue from "../../../FemilerCom/Logo/SLogoBlu";
const AddAdmin = () => {

    
    function handleSubmit(e) {
        e.preventDefault();
        let formData = {
            
                name: e.target["name"].value, 
                email: e.target["email"].value,
                password: e.target["password"].value,
                password_confirmation: e.target["password_confirmation"].value
            
        }
        console.log(e.target["name"].value)
        axios.post("https://api.twindix.com/dashboard/auth/register", formData, {
            headers: {
                Authorization: "Bearer " + localStorage.token
            }
        }).then(res => console.log(res))
    }
    return(
        <div className="inviteAdmin">
                <div className="InviteAdminLogoCon">
                    <LogoBlue />
                </div>
            <form onSubmit={handleSubmit} action="Post" className="InvteAdminCon d-flex flex-column align-items-center">
                <h3 className="InvitAdminH3">Invite New  Admin</h3>
                <input type="text" name="name" placeholder="name" id="name" required/>
                <input type="text" name="email" placeholder="email" id="email" required/>
                <input type="password" name="password"  placeholder="password" id="pass" required/>
                <input type="password" name="password_confirmation" placeholder="password confirmation" id="passCon" required/>
                <button className="btn btn-success" type="submit">Create Admin</button>
            </form>
        </div>
    )
}

export default AddAdmin;