import { Link, useNavigate } from "react-router-dom"
import LogInBox from "../../Componants/LogIn/LogInCom"
import Footer from "../../FemilerCom/Footer/Footer"
import Header from "../../FemilerCom/Header/Header"
import { useEffect } from "react"

function LogIn () {
    let navigate = useNavigate()
    useEffect(() => {
        if(localStorage.token) {
            if(localStorage.userType == 2) {
                navigate("/individual", {replace: true})
            } else if(localStorage.userType == 1) {
                navigate("/hr", {replace: true})
            }
        }
    },[])
    return (
        <>
            <LogInBox />
        </>
    )
}

export default LogIn