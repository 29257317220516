import { useEffect, useState } from "react";
import axios from "axios";
import "./shousers.css";
import { useNavigate } from "react-router";

const Exams = () => {
  const [users, setUsers] = useState();
  const [links, setLinks] = useState();
  const [loader, setLoader] = useState(false); // Ensure loader is initially set to false
  const [alertMsg, setAlertMsg] = useState({ code: false, msg: "", classes: "" });
  const [toggleType, setToggleType] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!users) {
      fetchExams("https://api.twindix.com/dashboard/exams");
    }
    console.log(users);
  }, [users]);

  const fetchExams = (url) => {
    setLoader(true);
    axios
      .get(url, {
        headers: { Authorization: "Bearer " + localStorage.token },
      })
      .then((res) => {
        setUsers(res.data.payload.data);
        setLinks(res.data.payload.links);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching exams:", error);
        setLoader(false);
      });
  };

  function requestData(url) {
    fetchExams(url);
  }

  function sortType() {
    let myUsers;
    if (toggleType) {
      myUsers = users.sort((a, b) => a.type - b.type);
    } else {
      myUsers = users.sort((a, b) => b.type - a.type);
    }
    setUsers([...myUsers]); // Spread to create a new array reference
    setToggleType(!toggleType);
  }

  function editExamEvent(examId) {
    sessionStorage.setItem("editId", examId);
    navigate("/editExam");
  }

  function dropUser(id) {
    axios.delete("https://api.twindix.com/dashboard/exams/" + id, {headers: {Authorization: "Bearer " + localStorage.token}}).then((res) => {
      console.log(res)
      if(res.status) {
        setAlertMsg({deleted: true,msg: res.data.message, classes: "alert alert-success alert-dismissible fade show"})
      } else {
        setAlertMsg({deleted: false,msg: "proplem occured", classes:"alert alert-danger alert-dismissible fade show"})
      }
      axios.get("https://api.twindix.com/dashboard/exams", {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
        setUsers(res.data.payload.data);
        setLinks(res.data.payload.links)
      })
    })
    
  }


  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className={loader ? "loader" : "d-none"}>
        <span className="spinner-border"></span>
      </div>
      <div className={alertMsg.msg ? "d-block position-fixed " : "d-none"} style={{ top: "42px" }}>
        <div className={alertMsg.classes}>
          {alertMsg.msg}{" "}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th
              style={{ cursor: "pointer", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }}
              className="tableheader"
              onClick={() => requestData("https://api.twindix.com/dashboard/packages")}
              scope="col"
            >
              id
            </th>
            <th
              style={{ cursor: "pointer" }}
              className="tableheader"
              onClick={() => requestData("https://api.twindix.com/dashboard/packages?sort=name")}
              scope="col"
            >
              Title
            </th>
            <th
              style={{ cursor: "pointer" }}
              className="tableheader"
              onClick={() => requestData("https://api.twindix.com/dashboard/packages?sort=description")}
              scope="col"
            >
              Description
            </th>
            <th
              style={{ cursor: "pointer" }}
              className="tableheader"
              scope="col"
            >
              Template Id
            </th>
            <th
              style={{ cursor: "pointer" }}
              className="tableheader"
              onClick={sortType}
              scope="col"
            >
              Price
            </th>
            <th
              style={{ cursor: "pointer", borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}
              className="tableheader"
              scope="col"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {users
            ? users.map((user, index) => (
                <tr key={user.id}>
                  <td className={index % 2 === 0 ? "tablerow" : ""} scope="row">
                    {user.id}
                  </td>
                  <td className={index % 2 === 0 ? "tablerow tablerowColor" : "tablerow"}>{user.title}</td>
                  <td className={index % 2 === 0 ? "tablerow tablerowColor" : "tablerow"}>{user.description}</td>
                  <td className={index % 2 === 0 ? "tablerow tablerowColor" : "tablerow"}>{user.template_id}</td>
                  <td className={index % 2 === 0 ? "tablerow tablerowColor text-success" : "tablerow text-success"}>{user.price}</td>
                  <td className={index % 2 === 0 ? "tablerow tablerowColor Flex" : "tablerow Flex"}>
                    <button style={{ margin: "4px" }} className="btn btn-danger" onClick={() => dropUser(user.id)}>
                      Delete
                    </button>
                    <button style={{ margin: "4px" }} onClick={() => editExamEvent(user.id)} className="btn btn-primary">
                      Edit Exam
                    </button>
                  </td>
                </tr>
              ))
            : "There is no Exams"}
        </tbody>
      </table>
    </div>
  );
};

export default Exams;
