import { useState, useEffect, useContext } from "react";
import axios from "axios";
import LoginVildation from "./LoginVildation";
import { redirect, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Button from "../../FemilerCom/Button/Button";
import "./LogIn.css"
import { appContext } from "../../App";
// import LogoBlu from "../../FemilerCom/Logo/LogoBlu";
import user from "../../Media/Icons/Forms/icons8-user-96.png"
import email from "../../Media/Icons/Forms/icons8-email-26.png"
import password from "../../Media/Icons/Forms/icons8-password-50.png"
import LogoWhi from "../../Media/Logo/WebsiteLogoWiht.png";
function LogInBox () {
let [error, seterror] = useState({email:"",password:""});
let [vlidation, setvlidation] = useState ({
        "email": "",
       "password": "",

    });
    const [finalErr, setFinalErr] = useState("");
    let isLoggedin = useContext(appContext);
    const navigate = useNavigate();


    let vlidationinput = (e) => {
        setvlidation(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    let VildationSupmitFun = (e) => {
        e.preventDefault();
        // console.log(vlidation)
        seterror(LoginVildation(vlidation))
    }

    useEffect(() => {
        if(localStorage.isLogged && localStorage.token && localStorage.userType) {
            console.log("hello")
            if(localStorage.userType == 2 && localStorage.isAdmin != true) {
            console.log("donneee")

                navigate("/individual");
            } else if(localStorage.userType == 1 && localStorage.isAdmin != true) {
                navigate("/hr")
            } else if(localStorage.isAdmin) {
                navigate('/admin')
            }
        } else if (error.email === 'valid' && error.password === 'valid') {
            // console.log("condition done")
        axios.post("https://api.twindix.com/auth/login", JSON.stringify(vlidation) ,{headers:{
            "Content-Type":"application/json",
            "Accept":"*",
        }}).then(res => {
            if(res.data.code == 200) {
                localStorage.token = res.data.payload.token;
                localStorage.isLogged = true;
                isLoggedin.setUserToken(localStorage.token);
                isLoggedin.setLoggedin(true);
                axios.get("https://api.twindix.com/auth/me", {headers:{
                    Authorization: 'Bearer ' + localStorage.token
                }}).then(res => {
            // console.log("me done")
                    localStorage.userType = res.data.payload.type;
                    if(res.data.payload.type == 2) {
                        isLoggedin.setUserType(2)
                        navigate("/individual", {replace: true});

                    } else if(res.data.payload.type == 1) {
                        isLoggedin.setUserType(1)
                navigate("/hr", {replace: true})

                    }
                })
            }else {
                setFinalErr("please check Your inputs again")
            }

        }).catch(res => console.log(res))

        }
      }
      , [error]); // Run this effect whenever the 'error' state changes

    return (
        <>
            <div className="LogIn">
            <div className="LogInContaner">
                <div className="LogInLogo">
                    <div ><img src={LogoWhi} alt="user" /></div>
                </div>
                <div className="LogInBox">
                    <div className="LogInText">
                        <div style={{backgroundColor: "#24509a", padding:"6px", borderRadius: "50%", marginRight: "4px"}}>
                        <img src={user} alt="ss" />
                        </div>
                        <h1 className="H1tex">CUSTOMER LOGIN</h1>
                    </div>
                    <form className="SupmitForm" onSubmit={VildationSupmitFun}>
                        <div className="InptCont">
                            <div className="ImgAndTilte">
                                <img className="ImgeInput" src={email} alt="ss" />
                                <div className="title">Email</div>
                                <input onChange={vlidationinput} className="RegInput"  name="email"    />
                            </div>
                            <div  className="ErrorText">{error.email == "valid" ? "" : error.email}</div>
                        </div>
                        <div className="InptCont">
                            <div className="ImgAndTilte">
                                <img className="ImgeInput" src={password} alt="ss" />
                                <div className="title">Password</div>
                                <input style={{textIndent: "40%"}}  onChange={vlidationinput} className="RegInput" type="password"  name="password"    />
                            </div>
                            <div className="ErrorText"> {error.password == "valid" ? "" : error.password}</div>
                            <div className="ErrorText"> {finalErr}</div>
                        </div>
                    <div className="LogInBtns">
                        <div className="RememberBox">
                            <input type="checkbox" />
                            <span>Remember me</span>
                        </div>
                        <div  id="HR" className="Contaner">
                            <Link to="/Login/ResetPassword">
                                <button className="RegsBTN" >Forget Password?</button>
                            </Link>
                        </div>
                    </div>
                        <div className="ToLOGIN" type="submit">
                            <Button Text="LOGIN"  />
                        </div>
                        {/* <div style={{color: "white",marginTop: "10px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center",}}>
                        Don't have an account,  <Link to={"Register"}>
                            <span style={{color: "white", borderBottom: "1px solid", padding: "4px", cursor: "pointer"}}>register now</span>
                            </Link>
                        </div> */}
                    </form>
                </div>  
                </div>
            </div>
        </>
    )
}

export default LogInBox;