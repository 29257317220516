import { useContext, useEffect, useState } from "react";
import "./Register.css"
import { Link, useNavigate } from "react-router-dom";
import RegisterValidation from "./RegisterValidation";
import axios from "axios";
import Button from "../../FemilerCom/Button/Button";
import swal from 'sweetalert';
import { appContext } from "../../App";
import LogoWhi from "../../Media/Logo/WebsiteLogoWiht.png";
import emailImg from "../../Media/Icons/Forms/icons8-email-26.png"
import password from "../../Media/Icons/Forms/icons8-password-50.png"
import user from "../../Media/Icons/Forms/icons8-user-96.png"
import work from "../../Media/Icons/Forms/icons8-work-24.png"
import company from "../../Media/Icons/Forms/icons8-company-24.png"
import phone from "../../Media/Icons/Forms/icons8-phone-50.png"
import user50 from "../../Media/Icons/Forms/icons8-user-50.png"
function RegisterBox () {
/*
hrsubmit is a state
VildationSupmitFun is a function runs when form is submitted
vlidationinput is a function runs when any input changes
*/
    let [hRSubmit, sethRSubmit] = useState(false)
    let [indvividualSubmit, setindvividualSubmit] = useState(true)
    //Start Register
    let [error, seterror] = useState([])
    const [errMsg, setErrMsg] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [submitForm, setSubmitForm] = useState(false);
    let [vlidation, setvlidation] = useState ({
        email: "",
        password: "",
        password_confirmation: "",
        phone: "",
        username: "",
        type: "",
        name: "",
        job_title: "",
        company_name: ""

    });

    const handleIndvividualClick = () => {
            sethRSubmit(false);
            setindvividualSubmit(true)

         // Set HR to false
        // setindvividualSubmit(prevState => !prevState); // Toggle Individual
    };

    const handleHRClick = () => {

        setindvividualSubmit(false); // Set Individual to false
        sethRSubmit(true); // Toggle HR
    };

    let navigate = useNavigate()

    let vlidationinput = (e) => {
        setvlidation(prev => ({...prev, [e.target.name]: e.target.value}))
    } 

    let VildationSupmitFun = (e) => {   
        e.preventDefault();
        seterror(RegisterValidation(vlidation))
        setSubmitForm(true);
    }


    useEffect(() =>{
            if (indvividualSubmit) {
                vlidation.type = 2
            }else if (hRSubmit) {
                vlidation.type = 1
            }

                if(submitForm) {
                axios.post('https://api.twindix.com/auth/register', vlidation, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then((res) => {
                    if(res.data.success) {
                        if (indvividualSubmit) {
                            navigate("/Individual")
                        }
                        if (hRSubmit) {
                            navigate("/HR")
                        }
                    }

                  // Additional logic after successful API request
                }).catch((err) => {
                    console.log(err);
                    setErrMsg(err.response.data.message);
                    setShowAlert(true);

                });
            }}, [error]); // Run this effect whenever the 'error' state changes
    //End Register
        let alert = () => {
            swal("Please Choose indvividual Or HR");

        }
    return (
        <>
            <div className="Rigster">
                <div className="RigsterContaner">
                    <div className="RigsterLogo">
                        <div ><img src={LogoWhi} alt="user" /></div>
                    </div>
                    <div className={hRSubmit ? "RigsterBox RigsterHr" : "RigsterBox"} >
                        <div className={showAlert ? "d-block position-fixed top-0" : "d-none"}>
                            <div class="alert alert-danger alert-dismissible fade show" role="alert">
                                {errMsg}
                                <button type="button" className="btn" onClick={() => {setShowAlert(false)}}  data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div className="Buttons">
                            <span className="RegsterBtn" onClick={handleIndvividualClick}>
                                <span><Button Text="Indvividual" /></span>
                            </span>
                            <span className="RegsterBtn" onClick={handleHRClick}>
                            <Button Text="HR" />
                            </span>
                        </div>
                        <div className={hRSubmit ? "smallRigsterBox HRBOX" : "smallRigsterBox"}  >
                        <div className="RegisterText">
                            <h1 className="H1tex">Register</h1>
                        </div>
                        <form className="SupmitForm" onSubmit={VildationSupmitFun}>
                            {indvividualSubmit && 
                            <>
                                <div className="InptCont">
                                    <div className="ImgAndTilte">
                                        <img className="ImgeInput" src={user50} alt="ss" />
                                        <div className="title">Name</div>
                                        <input onChange={vlidationinput} className="RegInput"  name="name"    />
                                    </div>
                                    <div  className="ErrorText">{error.name && <span>{error.name} </span>}</div>
                                </div>
                                <div className="InptCont">
                                    <div className="ImgAndTilte">
                                        <img className="ImgeInput" src={user} alt="ss" />
                                        <div className="title">username</div>
                                        <input onChange={vlidationinput} className="RegInput"  name="username"    />
                                    </div>
                                    <div  className="ErrorText">{error.username && <span>{error.username} </span>}</div>
                                </div>
                                <div className="InptCont">
                                    <div className="ImgAndTilte">
                                        <img className="ImgeInput" src={password} alt="ss" />
                                        <div className="title">password</div>
                                        <input onChange={vlidationinput} className="RegInput" type="password"  name="password"    />
                                    </div>
                                    <div className="ErrorText"> {error.password && <span >{error.password} </span>}</div>
                                </div>
                                <div className="InptCont">
                                    <div className="ImgAndTilte">
                                        <img className="ImgeInput" src={password} alt="ss" />
                                        <div className="title">Confirm Password</div>
                                        <input onChange={vlidationinput} className="RegInput" type="password"  name="password_confirmation"    />
                                    </div>
                                    <div className="ErrorText"> {error.passwordConf && <span >{error.passwordConf} </span>}</div>
                                </div>
                                <div className="InptCont">
                                <div className="ImgAndTilte">
                                    <img className="ImgeInput" src={emailImg} alt="ss" />
                                    <div className="title">email</div>
                                    <input onChange={vlidationinput} className="RegInput" type="email" name="email"   />
                                    </div>
                                    <div className="ErrorText">{error.email && <span >{error.email} </span>}</div>
                                </div>
                                <div className="InptCont">
                                <div className="ImgAndTilte">
                                    <img className="ImgeInput" src={phone} alt="ss" />
                                    <div className="title">phone</div>
                                    <input onChange={vlidationinput} className="RegInput"  name="phone" id="name"  />
                                    </div>
                                    <div className="ErrorText">
                                        {error.phone && <span >{error.phone} </span>}
                                        </div>
                                </div>
                            </>}
                            {hRSubmit && 
                            <>
                                    <div className="InptCont">
                                        <div className="ImgAndTilte">
                                            <img className="ImgeInput" src={user50} alt="ss" />
                                            <div className="title">Name</div>
                                            <input  onChange={vlidationinput} className="RegInput"  name="name"    />
                                        </div>
                                        <div  className="ErrorText">{error.username && <span>{error.username} </span>}</div>
                                    </div>
                                    <div className="InptCont">
                                        <div className="ImgAndTilte">
                                            <img className="ImgeInput" src={user} alt="ss" />
                                            <div className="title">username</div>
                                            <input onChange={vlidationinput} className="RegInput"  name="username"    />
                                        </div>
                                        <div  className="ErrorText">{error.username && <span>{error.username} </span>}</div>
                                    </div>
                                    <div className="InptCont">
                                        <div className="ImgAndTilte">
                                            <img className="ImgeInput" src={work} alt="ss" />
                                            <div className="title">job</div>
                                            <input onChange={vlidationinput} className="RegInput"  name="job_title"    />
                                        </div>
                                        <div  className="ErrorText">{error.username && <span>{error.username} </span>}</div>
                                    </div>
                                    <div className="InptCont">
                                        <div className="ImgAndTilte">
                                            <img className="ImgeInput" src={company} alt="ss" />
                                            <div className="title">Company Name</div>
                                            <input onChange={vlidationinput} className="RegInput"  name="company_name"    />
                                        </div>
                                        <div  className="ErrorText">{error.username && <span>{error.username} </span>}</div>
                                    </div>
                            {/* </div> */}
                                {/* <div className="HrBoxRight"> */}
                                    <div className="InptCont">
                                        <div className="ImgAndTilte">
                                            <img className="ImgeInput" src={password} alt="ss" />
                                            <div className="title">password</div>
                                            <input onChange={vlidationinput} className="RegInput" type="password"  name="password"    />
                                        </div>
                                        <div className="ErrorText"> {error.password && <span >{error.password} </span>}</div>
                                    </div>
                                    <div className="InptCont">
                                        <div className="ImgAndTilte">
                                            <img className="ImgeInput" src={password} alt="ss" />
                                            <div className="title">password Confirmation</div>
                                            <input onChange={vlidationinput} className="RegInput" type="password"  name="password_confirmation"    />
                                        </div>
                                        <div className="ErrorText"> {error.password && <span >{error.password} </span>}</div>
                                    </div>
                                    <div className="InptCont">
                                    <div className="ImgAndTilte">
                                        <img className="ImgeInput" src={emailImg} alt="ss" />
                                        <div className="title">email</div>
                                        <input onChange={vlidationinput} className="RegInput" type="email" name="email"   />
                                        </div>
                                        <div className="ErrorText">{error.email && <span >{error.email} </span>}</div>
                                    </div>
                                    <div className="InptCont">
                                    <div className="ImgAndTilte">
                                        <img className="ImgeInput" src={phone} alt="ss" />
                                        <div className="title">phone</div>
                                        <input onChange={vlidationinput} className="RegInput"  name="phone" id="name"  />
                                        </div>
                                        <div className="ErrorText">
                                            {error.phone && <span >{error.phone} </span>}
                                            </div>
                                    </div>
                                {/* </div> */}
                            {/* </div> */}
                                </>}
                            <div className="RegisterBtns">
                                <div id="Alert"onClick={alert} className={hRSubmit || indvividualSubmit ?"ToRegister  display": "ToRegister"} >
                                    <Button Text="Register" />  
                                </div>
                                { hRSubmit && 
                                <>
                                    <div  id="HR" className={indvividualSubmit ? "ToRegister display" : "ToRegister"} >
                                        <div type="submit" className="" >
                                            <Button Text="Register" />
                                        </div>
                                    </div>
                                </>}
                                {indvividualSubmit && 
                                <>
                                    <div id="Individual" type="submit" className={hRSubmit ? "ToRegister display" : "ToRegister"} >
                                            <Button Text="Register" />
                                    </div>
                                </>}
                            </div>
                            {/* <Link to="/Login">
                                <div className="ToLogin">
                                    <Button Text="Already Have Account" />
                                </div>
                            </Link> */}
                        </form>
                    </div> 
                </div>
                </div>
            </div>
        </>
    )
}

export default RegisterBox;