
function ContentBoxes (props) {
    return (
        <>
            <div className="ContentBoxes">
                <div>{props.quickcontent1}</div>
                <div>{props.quickcontent2}</div>
                <div>{props.quickcontent3}</div>
                <div>{props.quickcontent4}</div>
                <div>{props.quickcontent5}</div>
                <div>{props.quickcontent6}</div>
                <div>{props.quickcontent7}</div>
                <div>{props.quickcontent8}</div>
                <div>{props.quickcontent9}</div>
                <div>{props.quickcontent10}</div>
                <div>{props.quickcontent11}</div>
            </div>
        </>
    )
}

export default ContentBoxes